import React from 'react';
import { InputAdornment } from '@mui/material';
import SelectDynamicField from '../../Input/SelectDynamicField';
import InputDynamicField from '../../Input/InputDynamicField';
import { DatePickerFieldRegister } from '../../Input/DatePickerField';

export default function DynamicAttributeField({ form, attribute, configuration, isEdit = null, attributeValue = null, onPatchProjectAttribute = () => {} }) {
  const {
    register,
    setValue,
    watch,
    formState: { errors },
  } = form;

  const { is_required: isRequired, key_name: keyName, multiple_value: isMultiple, name, value_type: valueType, format } = attribute;
  const optionField = { required: isRequired };
  if (valueType === 'Numeric') {
    optionField.valueAsNumber = true;
  }
  let regField = { ...register(keyName, { required: isRequired }) };
  if (valueType === 'Option') {
    const val = isEdit && attributeValue ? attributeValue.value : null;
    regField = { ...register(keyName, { required: isRequired, value: val }) };
  }
  let helperText = '';
  if (format?.note) {
    helperText = format?.note;
  }

  let html = null;
  switch (valueType) {
    case 'Option': {
      const defaultValue = isEdit && attributeValue ? attributeValue.value : null;
      html = (
        <SelectDynamicField
          register={regField}
          options={attribute.options}
          defaultValue={defaultValue}
          name={keyName}
          label={name}
          isMultiple={isMultiple}
          helperText={helperText}
          configuration={configuration}
          form={form}
          isEdit={!!isEdit}
          onPatchProjectAttribute={onPatchProjectAttribute}
        />
      );
      break;
    }
    case 'Text':
      html = (
        <InputDynamicField
          name={keyName}
          label={name}
          type="text"
          originType={valueType}
          isRequired={isRequired}
          register={regField}
          errors={errors}
          helperText={helperText}
          configuration={configuration}
          form={form}
          isEdit={!!isEdit}
          onPatchProjectAttribute={onPatchProjectAttribute}
        />
      );
      break;
    case 'Formula': {
      const val = isEdit && attributeValue ? String(attributeValue.value) : null;
      const objProps = {};
      if (val) {
        objProps.placeholder = val;
        objProps.ToInputLabelProps = { shrink: true };
      }
      html = (
        <InputDynamicField
          name={keyName}
          label={name}
          type="number"
          originType={valueType}
          isRequired={isRequired}
          placeholder={val || ''}
          // disabled={!configuration?.is_reference}
          title={format?.formula}
          register={regField}
          errors={errors}
          helperText={helperText}
          configuration={configuration}
          form={form}
          isEdit={!!isEdit}
          onPatchProjectAttribute={onPatchProjectAttribute}
          {...objProps}
        />
      );
      break;
    }
    case 'Numeric': {
      const inputProps = { step: 0.0001 };
      const ToInputProps = {};

      if (format?.unit) {
        ToInputProps.endAdornment = <InputAdornment position="end">{Array.isArray(format.unit) ? format.unit.join('|') : format.unit}</InputAdornment>;
        inputProps.max = 100;
      }
      ToInputProps.inputProps = inputProps;
      html = (
        <InputDynamicField
          name={keyName}
          label={name}
          type="number"
          originType={valueType}
          isRequired={isRequired}
          register={regField}
          errors={errors}
          inputProps={inputProps}
          ToInputProps={ToInputProps}
          helperText={helperText}
          configuration={configuration}
          form={form}
          onPatchProjectAttribute={onPatchProjectAttribute}
          isEdit={!!isEdit}
        />
      );
      break;
    }
    case 'Datetime':
      html = (
        <DatePickerFieldRegister name={keyName} label={name} isRequired={isRequired} register={regField} form={form} errors={errors} watch={watch} onPatchProjectAttribute={onPatchProjectAttribute} isEdit={!!isEdit} />
      );
      break;
    default:
      break;
  }
  return html;
}
