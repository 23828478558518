/* eslint-disable max-len */
import { Button, Checkbox, Chip, CircularProgress, ClickAwayListener, FormControlLabel, FormGroup } from '@mui/material';
import React, { Suspense, useEffect, useState } from 'react';
import { GoogleMap, Marker, InfoWindow } from '@react-google-maps/api';
import { faLocationDot } from '@fortawesome/free-solid-svg-icons';
import { format } from 'date-fns';
import { useQuery } from '@tanstack/react-query';
import { useSelector } from 'react-redux';
import { KeyboardDoubleArrowDown, KeyboardDoubleArrowUp } from '@mui/icons-material';
import styled from '@emotion/styled';
import { Link } from 'react-router-dom';

import crmApi from '../../../api/CRM/CRMCustomers';
import { Item } from '@radix-ui/react-accordion';

const markerIcon = (pin, d, markerColor) => ({
  path: faLocationDot.icon[4],
  // fillColor: pin.requestedMarkerId === d.id ? '#E74C3C' : markerColor,
  fillColor: markerColor,
  anchor: new window.google.maps.Point(faLocationDot.icon[0] / 2, faLocationDot.icon[1]),
  fillOpacity: pin.requestedMarkerId === d.id ? 1 : 0.9,
  strokeWeight: 0.3,
  scale: 0.075,
});

const ContainerFilter = styled.div`
  opacity: ${(props) => (props.open ? '1' : '0')};
  max-height: ${(props) => (props.open ? '100%' : '0')};
  overflow: hidden;
  padding-bottom: 16px;
  padding: ${(props) => (props.open ? '16px 0px 0px 0px' : '0')};
  transition: all 0.1s;
`;

const { getCRMAttributeFilter, getCRMProjects, getCRMProjectProfiles, getCountries } = crmApi();

export default function Locations({ data }) {
  const activeClient = useSelector((state) => state.client.activeClient);

  const [activeMarker, setActiveMarker] = useState(null);
  const [pin, setPin] = useState({});
  const [filters, setFilters] = useState(null);
  const [openFilter, setOpenFilter] = useState(null);
  const [centerPosition, setCenterPosition] = useState(null);
  const attrProfile = 'project_profile';
  const attrCountry = 'country';
  const [projectProfileFilter] = useState([]);
  const [dataProject, setDataProject] = useState([]);

  useQuery({
    queryKey: ['crm-projects', activeClient, filters, projectProfileFilter],
    enabled: !!activeClient,
    queryFn: () => getCRMProjects({ profile__client: activeClient, max_size: true, ordering: '-id', ...filters }),
    onSuccess: (data) => {
      if (filters) {
        const profile = filterProject(data, 'profile.name', filters[attrProfile]);
        const country = filterProject(profile, 'location.country.name', filters[attrCountry]);
        setDataProject(country);
      } else {
        setDataProject(data);
      }
    },
    onError: (error) => {
      console.log({ error });
    },
  });

  // console.log("data ", data);
  // console.log("dataProject ", dataProject);
  // console.log("filters ", filters);

  // data by project
  // key by project like that project.profuile.name
  // filter values for filter data project
  const filterProject = (data, key, filter = []) => {
    const getProperty = (obj, prop) => {
      const props = prop.split('.');
      let value = obj;
      for (let i = 0; i < props.length; i++) {
        if (value != null) {
          value = value[props[i]];
        } else {
          break;
        }
      }

      return value;
    };

    const list = [];

    if (filter.length > 0) {
      data?.map((item) => {
        const propertyValue = getProperty(item, key);
        if (propertyValue != null && filter.indexOf(propertyValue) != -1) {
          list.push(item);
        }
      });
      return list;
    } else {
      return data;
    }
  };

  const { data: dataFilter } = useQuery({
    queryKey: ['crm-project-attribute-filter'],
    queryFn: () => getCRMAttributeFilter({ attribute__client: activeClient, type: 'Filter' }),
    onSuccess: (data) => {
      // console.log({ data });
    },
    refetchOnWindowFocus: false,
  });

  // https://api.bzpublish.com/countries/
  const { data: dataCountry } = useQuery({
    queryKey: ['countries'],
    queryFn: () => getCountries({ max_size: true }),
    onSuccess: (data) => {
      // console.log({ data });
    },
    refetchOnWindowFocus: false,
  });

  const { data: dataFilterProfile } = useQuery({
    queryKey: ['crm-project-profiles'],
    queryFn: () => getCRMProjectProfiles({ attribute__client: activeClient, type: 'Filter' }),
    onSuccess: (data) => {
      // console.log({ data });
    },
    refetchOnWindowFocus: false,
  });

  // const defaultCenter = { lat: 0, lng: 0 };
  let locations = null;

  const handleActiveMarker = (marker) => {
    if (marker === activeMarker) {
      return;
    }
    setActiveMarker(marker);
  };

  const handleChangePin = (id) => {
    setPin({ requestedMarkerId: id });
  };

  const handleFilter = (attr, val, name) => {
    if (filters) {
      setFilters((state) => {
        const isAttrExist = attr in state;
        const currState = state;
        let selectedAttr = {};
        if (isAttrExist) {
          if (val) {
            selectedAttr = currState[attr]?.length ? currState[attr].push(name) : (currState[attr] = [name]);
          } else {
            selectedAttr[attr] = currState[attr].filter((a) => a !== name);
          }
        } else {
          selectedAttr[attr] = [name];
        }
        return { ...state, ...selectedAttr };
      });
    } else {
      const f = { [attr]: [name] };
      setFilters(f);
    }
  };

  const usedData = filters ? dataProject : data;
  if (usedData) {
    const dt = [...usedData].filter((d) => !!d.location);

    locations = dt?.map((d) => {
      let locationInfo = {};
      locationInfo['name'] = d.name;
      locationInfo['mW'] = d?.attributes?.mw ? (typeof d.attributes.mw === 'object' ? d?.attributes.mw?.value : d?.attributes?.mw) : '';
      locationInfo['gWH'] = d?.attributes?.gwh ? (typeof d.attributes.gwh === 'object' ? d?.attributes.gwh?.value : d?.attributes?.gwh) : '';

      const startConstruction = d?.attributes?.construction_period_start ? format(new Date(d?.attributes?.construction_period_start), 'yyyy') : '';
      const endConstruction = d?.attributes?.construction_period_complete ? format(new Date(d?.attributes?.construction_period_complete), 'yyyy') : '';
      locationInfo['constructionYear'] = endConstruction || startConstruction || '-';
      if (endConstruction && startConstruction && +endConstruction > 0 && +startConstruction > 0 && +endConstruction !== +startConstruction) {
        locationInfo.constructionYear = `${startConstruction} - ${endConstruction}`;
      }

      const markerAttribute = d?.attributes ? d.attributes.prospect_priority : '';
      let markerColor = '#E74C3C';
      const dataFilterSelected = dataFilter?.find((f) => f.attribute.key_name === 'prospect_priority');
      if (dataFilter && dataFilterSelected && dataFilterSelected.attribute.options) {
        const colorFromAttr = dataFilterSelected.attribute.options.find((opt) => opt.name === markerAttribute);
        if (colorFromAttr) markerColor = colorFromAttr.configuration?.color || '#E74C3C';
      }

      return (
        <Marker
          key={d.id}
          animation={window.google.maps.Animation.DROP}
          position={{
            lat: d.location.latitude,
            lng: d.location.longitude,
          }}
          icon={markerIcon(pin, d, markerColor)}
          title={String(d.name)}
          onClick={() => [handleActiveMarker(d.id), handleChangePin(d.id)]}
        >
          {activeMarker === d.id ? (
            <InfoWindow onCloseClick={() => [setActiveMarker(null), setPin({})]}>
              <div className="w-[296px] h-full px-2 py-2">
                <Link to={`/crm/projects/detail/${d.id}`} className="font-semibold">
                  {d.name}
                </Link>
                <div className="grid grid-cols-2 gap-2 mt-2">
                  <p className="font-medium">Capacity</p>
                  <p className="font-medium">{locationInfo.mW ? locationInfo.mW.toFixed(2) : '-'} MW</p>

                  <p className="font-medium">Production</p>
                  <p className="font-medium">{locationInfo.gWH ? locationInfo.gWH.toFixed(2) : '-'} GWh</p>

                  <p className="font-medium">Construction Year</p>
                  <p className="font-medium">{locationInfo.constructionYear}</p>

                  <p className="font-medium">Country</p>
                  <p className="font-medium">{d.location?.country?.name || '-'}</p>
                </div>
              </div>
            </InfoWindow>
          ) : null}
        </Marker>
      );
    });
  }

  console.log({ locations });

  useEffect(() => {
    if (usedData && !centerPosition) {
      const dt = [...usedData].filter((d) => !!d.location);
      const centerLat = (dt.reduce((a, b) => a + +b.location.latitude, 0) / dt.length).toFixed(6);
      const centerLng = (dt.reduce((a, b) => a + +b.location.longitude, 0) / dt.length).toFixed(6);
      const newCenterPosition = { lat: +centerLat, lng: +centerLng };
      setCenterPosition(newCenterPosition);
    }
  }, [setCenterPosition, usedData, centerPosition]);

  return (
    <Suspense fallback={<CircularProgress />}>
      {/* {usedData && ( */}
      <ClickAwayListener onClickAway={() => {}}>
        <div className="relative d-flex flex-column mt-4" style={{ width: '100%' }}>
          <GoogleMap
            mapContainerStyle={{ width: '100%', height: 'calc(100vh - 296px)' }}
            center={centerPosition}
            zoom={3}
            className="border-2 "
            options={{
              fullscreenControl: false,
              mapTypeControl: false,
              streetViewControl: false,
            }}
          >
            {locations}
          </GoogleMap>

          {/* {dataFilter && ( */}
          <section className="py-4 pr-4 bg-white bg-opacity-90 rounded-sm absolute z-[1] top-0 left-0">
            <div className="relative min-w-48 min-h-8 h-full flex flex-col">
              <div className="pl-4">
                <button type="button" onClick={() => setOpenFilter(!openFilter)} className="flex justify-between items-center w-full">
                  <span className="font-bold">Filter</span>
                  {!openFilter ? <KeyboardDoubleArrowDown className="" /> : <KeyboardDoubleArrowUp className="" />}
                </button>
              </div>

              <ContainerFilter open={!!openFilter} className="flex flex-col">
                <div className="flex max-h-80">
                  {dataFilter?.map((f, i) => {
                    return (
                      <div key={f.attribute.name} className="flex flex-col overflow-y-auto">
                        <span className="font-bold mb-2 px-4">{f.attribute.name}</span>
                        <div className="flex flex-col flex-wrap gap-1">
                          {f?.attribute?.options.map((o) => {
                            const bg = o.configuration?.color || '#000000';
                            const checked = !!filters && !!filters[f.attribute.key_name] && !!filters[f.attribute.key_name].includes(o.name);
                            return (
                              <FormGroup key={o.name} className="flex px-4">
                                <FormControlLabel
                                  control={<Checkbox name={o.name} checked={checked} onChange={(e) => handleFilter(f.attribute.key_name, e.target.checked, o.name)} />}
                                  label={<Chip label={o.name} className="bg-opacity-70 cursor-pointer" sx={{ backgroundColor: bg, fontWeight: '700' }} />}
                                />
                              </FormGroup>
                            );
                          })}
                        </div>
                      </div>
                    );
                  })}
                  <div className="overflow-y-auto">
                    <span className="font-bold mb-2 px-4 ">Project Profile</span>

                    {dataFilterProfile?.map((o, i) => {
                      o.color = '#00000';
                      const checked = !!filters && !!filters[attrProfile] && !!filters[attrProfile].includes(o.name);
                      return (
                        <FormGroup key={i} className="flex px-4">
                          <FormControlLabel
                            control={<Checkbox name={o.name} checked={checked} onChange={(e) => handleFilter(attrProfile, e.target.checked, o.name)} />}
                            label={<Chip label={o.name} className="bg-opacity-70 cursor-pointer" sx={{ backgroundColor: Item.color, fontWeight: '700' }} />}
                          />
                        </FormGroup>
                      );
                    })}
                  </div>

                  {/* country */}
                  <div className="overflow-y-auto">
                    <span className="font-bold mb-2 px-4 ">Country</span>
                    {dataCountry?.data?.results?.map((o, i) => {
                      o.color = '#00000';
                      const checked = !!filters && !!filters[attrCountry] && !!filters[attrCountry].includes(o.name);
                      return (
                        <FormGroup key={i} className="flex px-4">
                          <FormControlLabel
                            control={<Checkbox name={o.name} checked={checked} onChange={(e) => handleFilter(attrCountry, e.target.checked, o.name)} />}
                            label={<Chip label={o.name} className="bg-opacity-70 cursor-pointer" sx={{ backgroundColor: Item.color, fontWeight: '700' }} />}
                          />
                        </FormGroup>
                      );
                    })}
                  </div>
                </div>
                <hr className="my-2" />
                <Button variant="text" className="ml-auto" onClick={() => setFilters(null)}>
                  Clear
                </Button>
              </ContainerFilter>
            </div>
          </section>
        </div>
      </ClickAwayListener>
      {/* )} */}
    </Suspense>
  );
}
