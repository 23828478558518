import { AccountTree } from '@mui/icons-material';
import UnitList from '../pages/HR/BusinessUnit/UnitList';
import LocationCityIcon from '@mui/icons-material/LocationCity';
import Vendor from '@/pages/Procurement/Vendor';
import VendorDetail from '@/pages/Procurement/VendorDetail';
import ProductDetails from '@/pages/ProductManagement/ProductDetails';
import ProductDetailNew from '@/pages/ProductManagement/ProductDetailNew';

const ProcurementRoute = [
  {
    name: 'Vendor',
    element: <Vendor />,
    icon: <LocationCityIcon />,
    privilege: [19, 20],
    subscriptions: [11],
    path: '/procurement/vendor',
    isSidebar: true,
  },
  {
    name: 'Vendor Add',
    element: <VendorDetail />,
    privilege: [19, 20],
    subscriptions: [11],
    path: '/vendor/add',
  },
  {
    name: 'Vendor Detail',
    element: <VendorDetail />,
    privilege: [19, 20],
    subscriptions: [11],
    path: '/vendor/detail/:vendorId',
  },
  {
    name: 'Product Detail',
    element: <ProductDetailNew />,
    privilege: [19, 20],
    subscriptions: [11],
    path: '/vendor/:vendorId/product/detail/:productId',
    isSidebar: false,
  },
  {
    name: 'Add Product',
    element: <ProductDetailNew />,
    privilege: [19, 20],
    subscriptions: [11],
    path: '/vendor/:vendorId/product/add',
    isSidebar: false,
  },
];

export default ProcurementRoute;
