/* eslint-disable */
import React, { useState, useMemo, useCallback, useEffect, useLayoutEffect } from 'react';
import { useForm, Controller } from 'react-hook-form';
import { useSearchParams, Link } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DatePicker } from '@mui/x-date-pickers';
import AddIcon from '@mui/icons-material/Add';
import { GridView, Search, ViewList } from '@mui/icons-material';
import { Checkbox, InputBase, TableCell, TablePagination, TableRow, Tooltip } from '@mui/material';
import moment from 'moment';
import _ from 'lodash';
import MuiSelectSingleItem from '../../components/Input/MuiSelectSingleItem';
import CustomSelectItem from '../../components/Input/CustomSelect';
import ProductManagementApi from '@/api/ProductManagement/ProductManagementApi';
import BZHelmet from '@/utils/BZHelmet';
import BaseMainTitle from '@/components/BaseMainTitle';
import CustomTable from '@/components/Table/CustomTable'
import Swal from 'sweetalert2';
import { setFilterValue, resetProductListState } from '@/store/slices/ProductManagement/productlistFilter';
import BzThumbnail from '@/assets/simple.png'
import TruncateMarkup from 'react-truncate-markup';
import { fetchData } from '@/utils/fetchDataFunc';

const headCells = [
  {
    id: 'id',
    numeric: false,
    disablePadding: true,
    label: 'Item Number',
  },
  {
    id: 'name',
    numeric: false,
    disablePadding: false,
    label: 'Name',
  },
  {
    id: 'status',
    numeric: false,
    disablePadding: false,
    label: 'Status',
  },
  {
    id: 'collection',
    numeric: false,
    disablePadding: false,
    label: 'Collection',
  },
  {
    id: 'category',
    numeric: false,
    disablePadding: false,
    label: 'Category',
  },
  {
    id: 'Currency',
    numeric: false,
    disablePadding: false,
    label: 'Currency',
  },
  {
    id: 'Selling Price',
    numeric: false,
    disablePadding: false,
    label: 'Selling Price',
  },
];

function CollectionCell(props) {
  const { data } = props;
  
  return data.length > 0 && (
    <ul className="">
      {data?.map((col) => (
        <li key={col} className="text-xs list-disc">
          {col || ""}
        </li>
      ))}
    </ul>
  );
}

function NameCell(props) {
  const { name, id } = props;
  const TEMPLATE_ID = useSelector((state) => state.content.activeTemplate);
  return (
    <div className="">
      <Link to={`/product-management/products/detail/${id}`} className="text-bz-green font-semibold">
        {name}
      </Link>
    </div>
  );
}

const formatNominal = (value) => {
  if (value === null || value === undefined){
    return ""
  }

  const parts = value.toString().split('.');
  parts[0] = parts[0].replace(/\B(?=(\d{3})+(?!\d))/g, ',');
  return parts.join('.');
};

function TableCells(props) {
  const { row, labelId, ariaChecked, selected, handleClick } = props;

  return (
    <TableRow hover role="checkbox" key={row?.id}>
      <TableCell padding="checkbox">
        <Checkbox color="primary" checked={ariaChecked} inputProps={{ 'aria-labelledby': labelId }} />
      </TableCell>
      <TableCell component="th" id={labelId} scope="row" padding="2">
        {row?.item_number}
      </TableCell>
      <TableCell align="left">
        <NameCell name={row?.name || "-"} id={row?.id} />
      </TableCell>
      <TableCell align="left">{row?.status || "-"}</TableCell>
      <TableCell align="left">
        { row?.collections ? <CollectionCell data={row?.collections} />: ""}
      </TableCell>
      <TableCell style={{ whiteSpace: 'nowrap' }} align="left">
        {row?.category?.name}
      </TableCell>
      <TableCell style={{ whiteSpace: 'nowrap' }} align="left">
        {row?.currency}
      </TableCell>
      <TableCell style={{ whiteSpace: 'nowrap' }} align="left">
        {formatNominal(row?.selling_price)}
      </TableCell>
    </TableRow>
  );
}

function ProductList() {
  const { control, reset, resetField } = useForm();
  const clientId = useSelector((state) => state.client.activeClient);
  const dispatch = useDispatch()

  const [products, setProducts] = useState([]);  
  const [count, setcount] = useState(0);
  const [isFetching, setIsFetching] = useState(true);
  const [orderQuery, setOrderQuery] = useState('');
  const [ordeIsAsc, setOrdeIsAsc] = useState('');
  const [checkboxSelected, setCheckboxSelected] = useState([]);
  const [isGrid, setIsGrid] = useState(true);

  // FILTER OPTIONS
  const statusOptions = [
    { label: "draft", value: "draft" },
    { label: "active", value: "active" },
    { label: "archive", value: "archive" },
  ];
  const [collectionsOptions, setCollectionsOptions] = useState([]);
  const [categoriessOptions, setCategoriesOptions] = useState([]);
  
  // TABLE FILTER
  const filters = useSelector(state => state.productListFilter);
  const { search, status, collection, category, sellable, internal, page, rowsPerPage } = filters;

  const getProductList = useCallback((params) => fetchData(ProductManagementApi().getProductList, params), [clientId]);
  const getCollectionList = useCallback((params = { client: clientId, max_size: true }) => fetchData(ProductManagementApi().getCollections, params), [clientId]);
  const getCategoryList = useCallback((params = { client: clientId, max_size: true }) => fetchData(ProductManagementApi().getCategories, params), [clientId]);

  const getProducts = useCallback(
    async (newParams) => {
      const [products] = await Promise.all([getProductList(newParams)]);

      if (products) {
        setProducts(products?.results);
        setcount(products?.count);
      } 
      
      setIsFetching(false) 
    },
    [getProductList]
  );

  const getFilterOptions = useCallback(
    async () => {
      const [collections, categories] = await Promise.all([getCollectionList(), getCategoryList()]);

      if (collections && categories) {
        setCollectionsOptions(collections?.results);
        setCategoriesOptions(categories?.results)
      }
    },
    [getProductList]
  );

  useEffect(() => {
    setIsFetching(true);
    setProducts([]);

    const newParams = {
      page: page,
      page_size: rowsPerPage,
      client: clientId,
    };

    if (collection) {
      newParams.collections = collection;
    }

    if (status) {
      newParams.status = status;
    }

    if (category) {
      newParams['category__name'] = category;
    }

    if (search) {
      newParams.search = search;
    }

    if (sellable) {
      newParams.sellable_product = true;
    }

    if (internal) {
      newParams.internal_product = true;
    }

    // setParamProducts(newParams);
    setIsFetching(true)
    getProducts(newParams)
  }, [collection, status, category, page, rowsPerPage, search, sellable, internal]);

  useEffect(() => {
    getFilterOptions();
  }, [clientId])

  const onFilterProducts = (value, filter) => {    
    dispatch(setFilterValue({ key: filter, value }));
    dispatch(setFilterValue({ key: 'page', value: 1 }));
  };

  const onChangePageAndRowsPerPage = (value, filter) => {
    dispatch(setFilterValue({ key: filter, value }));
  };

  const exportProductsToExcel = () => {
    const { exportProductListToExcel } = ProductManagementApi();

    try {
      const params = {
        max_size: true,
        client: clientId,
      };

      if (collection) {
        params.collections = collection;
      }
    
      if (status) {
        params.status = status;
      }
      
      if (category) {
        params['category__name'] = category;
      }
      
      if (search) {
        params.search = search;
      }
      
      if (sellable === true) {
        params.sellable_product = true;
      }
      
      if (internal === true) {
        params.internal_product = true;
      }

      exportProductListToExcel(params)
        .then((res) => {
          console.log(res);
        })
        .catch((err) => {
          console.error(err);
        });
    } catch (error) {
      console.error(error)
    }
  }

  const InputLabel = ({ children }) => <p className="font-bold">{children}</p>;

  const handleChangePage = (event, newPage) => {
    const watchNextPage = event.target.outerHTML.includes('KeyboardArrowRightIcon');
    let switchMathPage = watchNextPage ? newPage + 1 : newPage === 0 ? 1 : newPage + 1;

    onChangePageAndRowsPerPage(switchMathPage, "page");
    handleRefetch();
  };

  const handleChangeRowsPerPage = (event) => {
    onChangePageAndRowsPerPage(parseInt(event.target.value, 10), 'rowsPerPage');
    handleRefetch();
  };

  const countPage = Math.floor(count / rowsPerPage);
  const startIndex = page * rowsPerPage + 1;
  const endIndex = Math.min(startIndex + rowsPerPage - 1, count);
  
  return (
    <div className="w-full h-full bg-white">
      <BZHelmet title="Product List" description="" content="" />
      <BaseMainTitle title="Product List" />
      <div className="w-full p-5 relative">
        {/* Filter section */}
        <div className="lg:flex justify-between space-y-4">
          <div className='flex gap-4 flex-wrap '>
            <div className="w-[12rem]">
              <div>
                <InputLabel>Status</InputLabel>
              </div>
              <MuiSelectSingleItem
                sx={{ height: '40px', py: 0 }}
                FormControlClasses="h-[2.5rem]"
                name="status"
                label="status"
                OptionLabel="status"
                control={control}
                options={statusOptions}
                defaultValue={status}
                onChangeValue={(v) => {
                  onFilterProducts(v, "status");
                  // store.dispatch({ type: 'crmFilter/setCountries', payload: v });
                }}
                isInputLabel
              />
            </div>
            <div className="w-[12rem]">
              <div>
                <InputLabel>Collection</InputLabel>
              </div>
              <MuiSelectSingleItem
                sx={{ height: '40px', py: 0 }}
                FormControlClasses="h-[2.5rem]"
                name="collection"
                label="collection"
                OptionLabel="collection"
                control={control}
                options={collectionsOptions.map((dt) => ({ label: dt.name, value: dt.id }))}
                defaultValue={collection}
                onChangeValue={(v) => {
                  onFilterProducts(v, "collection");
                  // store.dispatch({ type: 'crmFilter/setCountries', payload: v });
                }}
                isInputLabel
              />
            </div>
            <div className="w-[12rem]">
              <div>
                <InputLabel>Category</InputLabel>
              </div>
              <MuiSelectSingleItem
                sx={{ height: '40px', py: 0 }}
                FormControlClasses="h-[2.5rem]"
                name="category"
                label="category"
                OptionLabel="category"
                control={control}
                options={categoriessOptions.map((dt) => ({ label: dt.name, value: dt.name }))}
                defaultValue={category}
                onChangeValue={(v) => {
                  onFilterProducts(v, "category");
                  // store.dispatch({ type: 'crmFilter/setCountries', payload: v });
                }}
                isInputLabel
              />
            </div>
            <div className="min-w-[6rem] w-[12rem]">
              <div>
                <InputLabel>Search</InputLabel>
              </div>
              <InputBase
                size="small"
                placeholder="search"
                endAdornment={<Search color="disabled" />}
                value={search}
                onChange={(v) => onFilterProducts(v.target.value, "search")}
                className="w-full h-[2.5rem] border border-[#BCB9B9] rounded px-4 bg-white hover:border-[#adacac]"
              />
            </div>
            <div className="flex gap-4">
              <div className="w-fit">
                <div>
                  <InputLabel>Sellable Product</InputLabel>
                </div>
                <Checkbox   
                  checked={sellable}
                  onChange={() => onFilterProducts(!sellable, "sellable")}
                />
              </div>
              <div className=" w-fit">
                <div>
                  <InputLabel>Internal Product</InputLabel>
                </div>
                <Checkbox   
                  checked={internal}
                  onChange={() => onFilterProducts(!internal, "internal")}
                />
              </div>
            </div>
          </div>
          <div className="flex items-end pb-2 justify-end">
            <Link
              to="/product-management/products/add"
              className="flex gap-x-2 p-2 items-center text-center btn btn-primary rounded-xl border-0 mr-2 px-5 cursor-pointer whitespace-nowrap"
              style={{ backgroundColor: '#2C6D47' }}
            >
              <AddIcon className="text-white" />
              <p className="text-white">Add New</p>
            </Link>
            <button
              className="flex gap-x-2 p-2 items-center text-center btn btn-primary rounded-xl border-0 mr-2 px-5 cursor-pointer whitespace-nowrap"
              style={{ backgroundColor: '#2C6D47' }}
              onClick={exportProductsToExcel}
            >
              <p className="text-white">Export To Excel</p>
            </button>
            <div name="grid-icon" className="ml-2 mb-1 mt-auto">
              <div className="space-x-2 justify-end md:justify-normal items-end">
                <Tooltip className="cursor-pointer">
                  {!isGrid ? <ViewList className="scale-125" onClick={() => setIsGrid(true)} /> : <GridView className="scale-125" onClick={() => setIsGrid(false)} />}
                </Tooltip>
              </div>
            </div>
          </div>
        </div>

        {
          !isGrid ? (
            <div className='ml-5'>
              <div className='grid grid-cols-2 sm:grid-cols-3 md:grid-cols-4 2xl:grid-cols-6 gap-6 mt-4'>                
                {
                  products?.slice(0, rowsPerPage).map((row, index) => {
                    return (
                      <div key={row.id} className="w-full relative">
                        <Link to={`/product-management/products/detail/${row.id}`} className="text-black">
                          <div className="h-full w-full gap-x-4 items-center hover:bg-[#F9F8F8] border border-transparent rounded-md pb-[16px] space-y-2 group">
                            <div
                              className={`relative w-full h-[15vh] flex items-center justify-center border-black border-[1.5px] border-dashed p-3 rounded-md `}
                            >
                              <img
                                src={row?.media_main || BzThumbnail}
                                alt=""
                                className={`object-contain w-full h-full aspect-[3/4] ${!row?.media_main && "grayscale"}`}
                              />
                            </div>
                            <TruncateMarkup className="space-y-1" lines={2}>
                              <div className="capitalize text-center font-medium group-hover:text-[#2C6D47] group-hover:font-semibold">{row?.name}</div>
                            </TruncateMarkup>
                          </div>
                        </Link>
                      </div>
                    )
                  })
                }
              </div>
              <div className="w-full py-4 mx-auto">
                <div className="my-5 flex items-center justify-end gap-x-5">
                <TablePagination
                  rowsPerPageOptions={[15, 20, 25, 50, 75, 100]}
                  component="div"
                  count={count}
                  rowsPerPage={rowsPerPage}
                  page={page - 1}
                  onPageChange={handleChangePage}
                  onRowsPerPageChange={handleChangeRowsPerPage}
                  labelDisplayedRows={({ from, to, count }) => {
                    return (
                      <span>
                        <span className="">
                          {' '}
                          Page {page} of {countPage + 1} -
                        </span>{' '}
                        Showing {startIndex} to {endIndex} of {count} {name || 'rows'}
                      </span>
                    );
                  }}
                />
                </div>
              </div>
            </div>
          ): (            
            <div className="mt-4">
              <CustomTable
                dataApi={products}
                isFetching={!isFetching}
                headCells={headCells}
                TableCells={TableCells}
                count={count}
                page={page - 1}
                rowsPerPage={rowsPerPage}
                setPage={v => onChangePageAndRowsPerPage(v, 'page')}
                setRowsPerPage={v => onChangePageAndRowsPerPage(v, 'rowsPerPage')}
                checkboxSelected={checkboxSelected}
                setCheckboxSelected={setCheckboxSelected}
                ordeIsAsc={ordeIsAsc}
                orderQuery={orderQuery}
                name="Product List"
                useCheckbox
              />
            </div>
          )
        }
      </div>
    </div>
  )
}

export default ProductList
