/* eslint-disable */
import React, { useState, useEffect, useCallback } from 'react';
import { Table, TableBody, TableCell, TableContainer, TableHead, TablePagination, TableRow } from '@mui/material/';
import CRMApi from '../../../api/CRM/CRMCustomers';

const HistoryHeadCells = [
  {
    id: 'action',
    numeric: false,
    disablePadding: true,
    label: 'Action',
  },
  {
    id: 'description',
    numeric: false,
    disablePadding: false,
    label: 'Description',
  },
  {
    id: 'timestamp',
    numeric: false,
    disablePadding: false,
    label: 'Date',
  },
];

function ViewHistory({ memberId }) {
  const [HistoryData, setHistoryData] = useState([]);
  const [rowsPerPage, setRowsPerPage] = useState(15);
  const [page, setPage] = useState(0);

  const getAllHistories = useCallback(async () => {
    const { getAllHistoriesByMemberId } = CRMApi();

    try {
      const fetchData = await getAllHistoriesByMemberId(memberId, { ordering: '-id', max_size: true });
      const response = fetchData.data.results;
      const data = response;

      setHistoryData(data);
    } catch (error) {
      console.error(error);
    }
  }, []);

  useEffect(() => {
    getAllHistories();
  }, []);

  console.log(HistoryData);

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const bgColor = {
    Create: '#D4FFD4',
    Update: '#FFFBE1',
    Delete: '#FFE1E1',
  };

  return (
    <div className="w-full relative">
      <TableContainer>
        <Table sx={{ minWidth: 750 }} aria-labelledby="tableTitle" size="medium">
        <TableHead>
          <TableRow>
            {HistoryHeadCells.map((headCell) => (
              <TableCell
                key={headCell.id}
                align={headCell.numeric ? 'right' : 'left'}
                padding={headCell.disablePadding ? 'none' : 'normal'}
                style={{ whiteSpace: 'nowrap', fontWeight: 'bold' }}
              >
                {headCell.label}
              </TableCell>
            ))}
          </TableRow>
        </TableHead>
          <TableBody>
            {HistoryData
              .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
              .map((row, index) => {
                const labelId = `enhanced-table-checkbox-${index}`;
                const date = new Date(row.timestamp).toLocaleString('en-US', { dateStyle: "long", timeStyle: "short", hour12: true});

                return (
                  <TableRow hover role="checkbox" tabIndex={-1} key={row.id}>
                    <TableCell component="th" id={labelId} scope="row" padding="none">
                      <span className="p-2 shadow-sm rounded-md" style={{ backgroundColor: bgColor[row.action] }}>
                        {row.action}
                      </span>
                    </TableCell>
                    <TableCell align="left">
                      {row.description === 'null' ? '' : row.description}
                    </TableCell>
                    <TableCell align="left">{row.timestamp === 'null' ? '' : date}</TableCell>
                  </TableRow>
                );
              })} 
          </TableBody>
        </Table>
      </TableContainer>
      <TablePagination
        rowsPerPageOptions={[15, 20, 25]}
        component="div"
        count={HistoryData.length}
        rowsPerPage={rowsPerPage}
        page={page}
        onPageChange={handleChangePage}
        onRowsPerPageChange={handleChangeRowsPerPage}
      />
    </div>
  );
}

export default ViewHistory;
