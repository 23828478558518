/* eslint-disable */
import { AccordionSummary } from '@mui/material';

import clsxm from '@/utils/clsxm';
import { useDispatch, useSelector } from 'react-redux';
import { handleActiveSide, handleOpenDrawer } from '../../store/slices/sidebarTaskSlice';

export default function RenderItems({ itm, child, style, action, childItm, setChildItm, stateDraw, setStateDraw, activeSide }) {
  const isSidebar = useSelector((state) => state.sidebar);
  // console.log({isSidebar});
  // console.log({child});
  // console.log({itm});
  const dispatch = useDispatch();

  return (
    <>
      <div
        onClick={() => {
          dispatch(handleOpenDrawer());
          if (isSidebar.activeSide === itm.name) {
            // dispatch(handleActiveSide({ name: itm.name, components: child }));
            // // dispatch(setChildItemRdx({ id: '', item: [] }));
            // setChildItm({ id: itm.name, item: child });
          } else {
            setChildItm({ id: itm.name, item: child });
            dispatch(handleActiveSide({ name: itm.name }));
            // dispatch(setChildItemRdx({ id: itm.name, item: child }));
          }
        }}
        key={itm.name}
        className={!isSidebar.isOpen && 'phx-4'}
      >
        <AccordionSummary
          className={[
            style,
            'items-center justify-center !mulish dddddddddddddddd',
            'px-4',
            // isSidebar.isOpen ? 'px-4' : 'pl-4',
            'my-[7.5px]',
            isSidebar.activeSide === itm.name ? 'bg-white rounded-[11px]' : 'bg-transparent',
          ].join(' ')}
          aria-controls={itm.name}
          id={itm.name}
          onClick={action}
        >
          <div data-tooltip-id={'tooltip-sidebar'} data-tooltip-content={itm.name} data-tooltip-variant="light" className="flex items-center">
            {' '}
            {itm.icon}
          </div>
          <p
            className={clsxm(
              'ml-2 !mulish text-[14px] fixed left-[60px]',
              !isSidebar.isOpen ? 'w-[0px]  opacity-0 ' : 'w-auto transition-all  duration-300',
              isSidebar.activeSide === itm.name ? 'text-black' : 'text-black/40'
            )}
          >
            {itm.name}
          </p>
        </AccordionSummary>
      </div>
    </>
  );
}
