import React, { useState, useMemo, useCallback, useEffect, useLayoutEffect } from 'react';
import ProductManagementApi from '@/api/ProductManagement/ProductManagementApi';
import { useDispatch, useSelector } from 'react-redux';
import { useDebounce } from 'use-debounce';
import { Avatar, InputAdornment, InputBase, Table, TableBody, TableCell, TableHead, TableRow, TextField, Tooltip, CircularProgress, Select, OutlinedInput, MenuItem } from '@mui/material';
import { GridView, Search, ViewList } from '@mui/icons-material';
import _ from 'lodash';
import { handleModal } from '@/store/slices/modalSlice';
import BzThumbnail from '@/assets/simple.png'
import POSApi from '@/api/pos/posApi';
import Swal from 'sweetalert2';

function OutletPairCollections({onSubmit}) {
  const scheduleOptions = [
    { label: "Monday", value: "Monday" },
    { label: "Tuesday", value: "Tuesday" },
    { label: "Wednesday", value: "Wednesday" },
    { label: "Thursday", value: "Thursday" },
    { label: "Friday", value: "Friday" },
    { label: "Saturday", value: "Saturday" },
    { label: "Sunday", value: "Sunday" },
  ];

  const activeClient = useSelector((state) => state.modal.modalId);
  const collectionsData = useSelector((state) => state.modal.modalData);
  const dispatch = useDispatch();
  
  const [allCollections, setAllCollections] = useState([]);
  const [collectionsSearch, setCollectionsSearch] = useState("");
  const [collectionsearchValue] = useDebounce(collectionsSearch, 300);

  const [listSelectCollections, setListSelectCollections] = useState([]);
  const [listPickCollections, setPickCollections] = useState([]);
  const [selectedSchedules, setSelectedSchedules] = useState([]);

  const handleSelectedCollections = (value, collectionId, collectionData) => {
    let newSelectedCollections = _.cloneDeep(listSelectCollections);
    let pickCollectionsClone = _.cloneDeep(listPickCollections);

    if (value === true) {
      newSelectedCollections.push(collectionData);
      pickCollectionsClone.push(collectionId);
    } else {
      newSelectedCollections = newSelectedCollections.filter((data) => data.id !== collectionId);
      pickCollectionsClone = pickCollectionsClone.filter((data) => data !== collectionId);
    }
      
    setListSelectCollections([...newSelectedCollections]);
    setPickCollections([...pickCollectionsClone]);
  };


  const getAllCollections = useCallback(async (paramCollections) => {
    const { getCollections } = ProductManagementApi();
  
    try {
      const getData = await getCollections(paramCollections);
      const data = await getData.data;
      
      const excludedIds = collectionsData?.pairComponentIds;
  
      const filteredCollections = data?.results.filter(item => !excludedIds?.includes(item.id));
  
      setAllCollections(filteredCollections);
    } catch (error) {
      console.error(error);
    }
  }, [activeClient, collectionsData?.pairComponentIds]);

  useEffect(() => {
    const params = new URLSearchParams(`client=${activeClient}&max_size=true`);

    if (collectionsearchValue) {
      params.set("search", collectionsearchValue.split(" ").join("+"));
    }

    getAllCollections(params);
  }, [activeClient, collectionsearchValue]);

  const ITEM_HEIGHT = 48;
  const ITEM_PADDING_TOP = 8;
  const MenuProps = {
    PaperProps: {
      style: {
        maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
        width: 250,
      },
    },
  };

  const SelectForm = ({defaultValue, label, name, multiple = false, options, disabled = false, onChangeValue}) => {
    return (
    <div className="w-full h-fit">
      <p className={`font-semibold mb-[4px]`}>{label}</p>
      <Select 
        className="w-full h-full"
        labelId={`label-${name}`}
        id={`select-${name}`}
        onChange={onChangeValue}
        value={defaultValue !== undefined ? defaultValue : []}
        displayEmpty
        multiple={multiple}
        disabled={disabled}
        MenuProps={MenuProps}
        input={<OutlinedInput label={label} />}
        sx={{
          minWidth: '100%',
          width: '100%',
          px: 0.2,
          py: 0.6,
          background: 'white',
        }}
      >
        {options.map((opt) => (
          <MenuItem key={opt.value} value={opt.value}>
            {opt.value}
          </MenuItem>
        ))}
      </Select>
    </div>
  )}

  const handleChangeSchedule = (event) => {
    setSelectedSchedules(event.target.value);
  };

  const SubmitPairCollections = () => {
    onSubmit(listPickCollections, selectedSchedules);
    dispatch(handleModal({ modalId: '', componentName: '', modalData: '' }));
  };

  const InputLabel = ({ children }) => <p className="font-bold">{children}</p>;

  console.log({allCollections});
  
  return (
    <div className="w-full flex space-x-5 relative" style={{ maxHeight: "1200px", overflowY: "hidden" }}>
      {
        allCollections?.length > 0 ?
          <>
            <div className="w-[70%] pr-2">
              <div className="grid grid-cols-3 gap-6 pb-2 pr-2 overflow-y-auto max-h-[800px]">
                {
                  allCollections?.map((dt) => (
                    <div key={dt.id} className="w-full border rounded-md shadow-sm">
                      <div className="flex justify-between bg-[#2C6D47] p-4 rounded-t-md">
                        <div className="text-white flex items-center">
                          <input
                            type="checkbox"
                            className="h-4 w-4"
                            id={`location-pair-${dt?.id}`}
                            checked={listPickCollections?.includes(dt?.id)}
                            onChange={(e) => {
                              handleSelectedCollections(e.target.checked, dt.id, dt);
                            }}
                          />
                          <label className="text-lg ml-2" htmlFor={`location-pair-${dt.id}`}>
                            <p>{dt.name}</p>
                          </label>
                        </div>
                      </div>
                      <div className="flex gap-x-2 min-h-[150px] p-2 justify-center items-center">
                        <img className={`scale-90 max-h-[150px] origin-top-left ${!dt?.thumbnail && "grayscale"}`} src={dt?.thumbnail || BzThumbnail} />
                      </div>
                    </div>
                  ))
                }
              </div>
            </div>
            <div className="w-[30%] space-y-5">
              <div className="w-full">
                <div>
                  <InputLabel>Search Collections</InputLabel>
                </div>
                <InputBase
                  size="small"
                  placeholder="search"
                  endAdornment={<Search color="disabled" />}
                  onChange={(v) => setCollectionsSearch(v.target.value)}
                  value={collectionsSearch}
                  className="w-full h-[2.5rem] border border-[#BCB9B9] rounded px-4 bg-white hover:border-[#adacac]"
                  />
              </div>
              <div className="w-full flex justify-end gap-x-3 pb-2 border-b">
                <button 
                  className="bg-[#2C6D47] flex h-[40px] gap-x-2 items-center text-center btn btn-primary rounded-xl border-0 px-4 text-white" 
                  variant="contained" 
                  onClick={SubmitPairCollections}
                  disabled={listPickCollections.length === 0}
                >
                  Submit
                </button>
              </div>
              <div className="space-y-3 px-2" style={{ maxHeight: "290px", overflowY: "auto" }}>
                {
                  listSelectCollections?.length > 0 && 
                    listSelectCollections.map((dt, idx) => (
                      <div key={dt?.id} className="w-full border rounded-md shadow-sm">
                        <div className="flex justify-between bg-[#2C6D47] p-4 rounded-md items-center text-white">
                          <div className=" flex items-center">
                            <input
                              type="checkbox"
                              className="h-4 w-4"
                              checked
                              onChange={(e) => {
                                handleSelectedCollections(e.target.checked, dt.id, dt);
                              }}
                              id={`location-pair-${dt?.id}`}
                            />
                            <label className="text-lg ml-2" htmlFor={`location-pair-${dt?.id}`}>{dt?.name}</label>
                          </div>
                        </div>
                      </div>
                    ))
                }
              </div>
              {/* <div className="space-y-3 px-2" style={{ maxHeight: "290px", overflowY: "auto" }}>
                <SelectForm
                  defaultValue={selectedSchedules}
                  // label="Select Schedule"
                  name="schedule"
                  options={scheduleOptions}
                  onChangeValue={handleChangeSchedule}
                  multiple={true}
                />
              </div> */}
            </div>
          </>
        : 
        <CircularProgress />
      }
    </div>
  )
}

export default OutletPairCollections