import { yupResolver } from '@hookform/resolvers/yup';
import {
  Box,
  Button,
  ButtonBase,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Fade,
  FormControl,
  InputLabel,
  MenuItem,
  Modal,
  Select,
  Skeleton,
  Slide,
  ThemeProvider,
  Typography,
  createTheme,
} from '@mui/material';
import { useQuery, useQueryClient } from '@tanstack/react-query';
import React, { useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import * as yup from 'yup';
import { useDropzone } from 'react-dropzone';
import { CKEditor } from '@ckeditor/ckeditor5-react';
import { Add, Close } from '@mui/icons-material';
import Editor from 'ckeditor5-custom-build/build/ckeditor';
import { useSelector } from 'react-redux';

import BaseLocation from '../Base/BaseLocation';
import Comment from '../../ui/Task/DrawerTask/Comment';
import AttachmentFiles from '../../ui/Task/DrawerTask/AttachmentFiles';
import DynamicAttribute from '../../Form/CRM/DynamicAttribute';
import StaticAttribute from '../../Form/CRM/StaticAttribute';

import crmApi from '../../../api/CRM/CRMCustomers';
import accountingApi from '../../../api/accounting/accounting';
import locationApi from '../../../api/content/location';

// const W = window.innerWidth;
const H = window.innerHeight;

const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: '90%',
  minHeight: 680,
  maxHeight: H,
  overflowY: 'auto',
  bgcolor: 'background.paper',
  border: '1px solid transparent',
  boxShadow: 24,
  p: 4,
  outline: 'none',
};

const groupArrayObject = (array) => {
  const groupObject = array.reduce(
    (group, arr) => {
      const gr = group;
      const { category } = arr;

      gr[category.name] = gr[category.name] ?? [];

      gr[category.name].push(arr);

      return gr;
    },

    {}
  );
  return groupObject;
};

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="down" ref={ref} {...props} />;
});

const {
  getCRMProfileAttribute,
  getDetailCRMProject,
  getFileTypeCRMProject,
  createCRMProject,
  postCRMProjectComments,
  postCRMProjectAttachments,
  deleteCRMProjectAttachment,
  getAllMembers,
} = crmApi();
const { getDetailLocation } = locationApi();
const { getListBusinessUnit } = accountingApi();

const staticSchema = yup.object().shape({ name: yup.string().required('Name is a required field') });

export default function CreateCRMProject({ open, close, refetch, isEdit, dataContact, refetchContact }) {
  const isUsername = useSelector((state) => state.auth.user?.name);
  const activeClient = useSelector((state) => state.client.activeClient);

  const [projectProfile, setProjectProfile] = useState(null);
  const [projectContact, setProjectContact] = useState([]);
  const [projectBusinessUnit, setProjectBusinessUnit] = useState(null);
  const [projectPartner, setProjectPartner] = useState([]);

  const [schema, setSchema] = useState(null);
  const [location, setLocation] = useState(null);
  const [openLocation, setOpenLocation] = useState(null);
  const [filesAttachment, setFilesAttachment] = useState([]);
  const [commentData, setCommentData] = useState('');
  // eslint-disable-next-line no-unused-vars
  const [isPopoverAttachments, setPopoverAttachments] = useState(false);
  const [projectFileType, setProjectFileType] = React.useState(false);

  const queryClient = useQueryClient();

  const {
    register,
    trigger,
    getValues,
    setValue,
    resetField,
    watch,
    formState: { errors },
    control,
  } = useForm({
    resolver: yupResolver(staticSchema),
    defaultValues: {},
  });

  const onCloseHandler = () => {
    close();
  };

  const { data: dataAttribute, isFetching: isFetchingAttribute } = useQuery({
    queryKey: ['crm-profile-attributes', projectProfile],
    enabled: !!projectProfile,
    queryFn: () => getCRMProfileAttribute({ client: activeClient, profile: projectProfile, max_size: true, ordering: '-index' }),
    onSuccess(res) {
      const properties = {};
      const requiredProperties = [];
      const keySchema = [];
      const errorProperties = {};
      res?.forEach((p) => {
        const attr = { ...p.attribute };
        const type = attr.value_type.toLowerCase();

        let usedType = type;
        if (['formula', 'numeric'].includes(type)) {
          usedType = 'number';
        } else if (['option', 'datetime', 'text'].includes(type)) {
          usedType = 'string';
        }
        properties[attr.key_name] = {
          type: usedType,
          required: attr.is_required,
          description: attr.name,
          origin: type,
          format: attr.format,
          configuration: p.configuration || null,
        };
        if (attr.is_required) {
          requiredProperties.push(attr.key_name);
          errorProperties[attr.key_name] = { required: 'Required field' };
        }
        if (type === 'option') {
          let options = attr.options.join('|');
          if (!attr.is_required) {
            options += '|null';
          }
          properties[attr.key_name].matches = `(${options})`;
        }
        if (type === 'numeric' && !attr.is_required) {
          properties[attr.key_name].default = null;
          properties[attr.key_name].nullable = true;
        }
        if (type === 'formula' && isEdit) {
          properties[attr.key_name].default = null;
          properties[attr.key_name].nullable = true;
        }
        keySchema.push(attr.key_name);
      });

      const newSchema = {
        $schema: 'http://json-schema.org/draft-07/schema#',
        $id: 'http://example.com/person.schema.json',
        title: 'Project',
        description: 'CRM Project',
        type: 'object',
        properties,
        required: requiredProperties,
      };

      setSchema({ schema: newSchema, errorSchema: errorProperties, keys: keySchema });
    },
    refetchOnWindowFocus: false,
  });

  const { data: project, refetch: refetchProjectDetail } = useQuery({
    queryKey: ['crm-project-detail', isEdit.id],
    enabled: !!isEdit,
    queryFn: () => getDetailCRMProject(isEdit.id),
    refetchOnWindowFocus: false,
    onSuccess(res) {
      if (res.files?.length) {
        const files = groupArrayObject(res.files);
        setFilesAttachment(files);
      }
    },
  });
  useQuery({
    queryKey: ['crm-project-location', location],
    enabled: !!isEdit && !!location && !!['string', 'number'].includes(typeof location),
    queryFn: () => getDetailLocation(location),
    refetchOnWindowFocus: false,
    onSuccess(res) {
      if (res.data) {
        setValue('location', res.data.name || res.data.address, { shouldDirty: true });
        setLocation(res.data);
      }
    },
  });
  const { data: dataBusinessUnit } = useQuery({
    queryKey: ['crm-project-business-unit', activeClient],
    queryFn: () => getListBusinessUnit({ client: activeClient, max_size: true }),
    refetchOnWindowFocus: false,
  });
  const { data: dataMembers, refetch: refetchPartner } = useQuery({
    queryKey: ['crm-project-members', activeClient],
    queryFn: () => getAllMembers({ client: activeClient, max_size: true }),
    refetchOnWindowFocus: false,
  });
  const { data: fileTypes } = useQuery({
    queryKey: ['crm-project-file-type', isEdit.id],
    enabled: !!isEdit && !!projectProfile,
    queryFn: () => getFileTypeCRMProject({ profile: projectProfile }),
    refetchOnWindowFocus: false,
  });

  const onMainFormSubmit = async () => {
    const isValid = await trigger(['name']);
    return isValid;
  };

  const onCreateProject = (formData) => {
    createCRMProject(
      {
        name: getValues('name'),
        contacts: projectContact?.length ? projectContact.map((c) => c.id) : [],
        location: location?.id || '',
        business_unit: projectBusinessUnit || null,
        partners: projectPartner?.length ? projectPartner.map((c) => c.id) : [],
        profile: projectProfile,
        attributes: formData,
      },
      !isEdit ? 'post' : 'patch',
      !isEdit ? null : isEdit.id
    )
      .then(() => {
        refetch();
        queryClient.invalidateQueries({ queryKey: ['crm-projects-list', activeClient] });
        onCloseHandler();
      })
      .catch((err) => console.log(err));
  };

  const pairLocation = (dataLocation) => {
    setValue('location', dataLocation.name || dataLocation.address, { shouldDirty: true });
    setLocation(dataLocation);
    setOpenLocation(false);
  };

  const onPostComment = () => {
    postCRMProjectComments({ project: isEdit.id, comment: commentData })
      .then(() => {
        setCommentData('');
        refetchProjectDetail();
      })
      .catch((err) => {
        console.log('err', err);
      });
  };

  useEffect(() => {
    if (!open) {
      resetField('name');
      setProjectProfile(null);
      setLocation(null);
    }
  }, [open, resetField]);

  useEffect(() => {
    let isInitialized = true;
    if (open && isInitialized && isEdit) {
      isInitialized = false;
      setValue('name', isEdit.name, { shouldTouch: true, shouldDirty: true, shouldValidate: true });
      setValue('location', isEdit.location?.id, { shouldTouch: true });
      setLocation(isEdit.location?.id);
      setProjectProfile(isEdit.profile?.id);
      trigger();
    }

    return () => {
      isInitialized = false;
    };
  }, [open, isEdit, setValue, trigger]);

  const deleteAttachments = (attachmentId) => {
    if (attachmentId) {
      deleteCRMProjectAttachment(attachmentId)
        .then(() => refetchProjectDetail())
        .catch((err) => console.log(err));
    }
  };

  const handleChangeContact = (event) => {
    const {
      // Config
      target: { value },
    } = event;
    const duplicateIds = value
      .map((e) => e.id)
      .map((e, i, final) => final.indexOf(e) !== i && i)
      .filter((obj) => value[obj])
      .map((e) => value[e].id);

    setProjectContact(typeof value === 'string' ? value.split(',') : value.filter((v) => !duplicateIds.includes(v.id)));
  };
  const handleChangePartners = (event) => {
    const {
      // Config
      target: { value },
    } = event;
    const duplicateIds = value
      .map((e) => e.id)
      .map((e, i, final) => final.indexOf(e) !== i && i)
      .filter((obj) => value[obj])
      .map((e) => value[e].id);
    setProjectPartner(typeof value === 'string' ? value.split(',') : value.filter((v) => !duplicateIds.includes(v.id)));
  };
  return (
    <>
      <Modal open={!!open} onClose={onCloseHandler} aria-labelledby="modal-modal-title" aria-describedby="modal-modal-description">
        <Fade in={!!open}>
          <Box sx={style}>
            <Typography id="create-crm-project" className="mb-4" variant="h6" component="h2">
              Project
            </Typography>
            <button onClick={onCloseHandler} type="button" className="absolute right-8 top-4">
              <Close />
            </button>
            <section>
              <div className="grid grid-cols-6 gap-8">
                <div className={isEdit ? 'col-span-4' : 'col-span-6'}>
                  <StaticAttribute
                    form={{ control, errors, register, watch }}
                    state={{ dataContact, projectContact, projectProfile, location, openLocation, isEdit, dataBusinessUnit, projectBusinessUnit, projectPartner, dataMembers }}
                    action={{ handleChangeContact, refetchContact, setOpenLocation, setProjectProfile, pairLocation, setProjectBusinessUnit, handleChangePartners, refetchPartner }}
                  />

                  {projectProfile && isFetchingAttribute && <Skeleton />}
                  {projectProfile && !isFetchingAttribute && schema && (
                    <DynamicAttribute
                      attributes={dataAttribute}
                      schema={schema}
                      onCloseHandler={onCloseHandler}
                      onCreateProject={onCreateProject}
                      triggerMain={trigger}
                      onMainFormSubmit={onMainFormSubmit}
                      isEdit={isEdit}
                    >
                      {}
                    </DynamicAttribute>
                  )}
                </div>
                {isEdit && (
                  <div className="col-span-2 border-l pl-8 max-h-[90vh] overflow-y-auto">
                    <div className="">
                      <div className="">
                        <InputLabel htmlFor="project-comment" className="mb-2">
                          Attachments
                        </InputLabel>
                        {filesAttachment &&
                          Object.keys(filesAttachment).map((file, index) => {
                            const contentAttachments = filesAttachment[file]?.map((att) => (
                              <AttachmentFiles key={att?.file} file={att} index={index} setPopoverAttachments={setPopoverAttachments} isCRM deleteAttachments={deleteAttachments} />
                            ));
                            return (
                              <div key={file}>
                                <span className="mb-2">{file}</span>
                                <div className="flex flex-wrap gap-4">{contentAttachments}</div>
                              </div>
                            );
                          })}
                        <div className="mt-4 mb-4 flex space-x-2 flex-wrap items-center">
                          <ButtonBase className="p-2 flex items-center justify-center border border-dashed border-gray-300 rounded-md cursor-pointer">
                            <Add className="w-[32px] h-[32px]" onClick={() => setProjectFileType(true)} />
                          </ButtonBase>
                        </div>
                      </div>
                      <div className="relative overflow-y-auto">
                        <InputLabel htmlFor="project-comment" className="mb-2">
                          Comment
                        </InputLabel>
                        {project &&
                          project.comments &&
                          project.comments.length > 0 &&
                          project.comments.map((c, index) => {
                            return (
                              <Comment
                                key={c.id}
                                comment={c}
                                isUsername={isUsername}
                                getTopicDetails={refetchProjectDetail}
                                index={index}
                                setPopoverAttachments={setPopoverAttachments}
                                isTopic={false}
                              />
                            );
                          })}
                        <CKEditor
                          id="project-comment"
                          placeholder="Comment"
                          className="ml-2 w-full border-0"
                          editor={Editor}
                          config={{ toolbar: { items: [] }, placeholder: '', minHeight: 172 }}
                          data={commentData}
                          onChange={(_, editor) => {
                            setCommentData(editor.getData());
                          }}
                          onReady={(editor) => {
                            editor.editing.view.change((writer) => {
                              writer.setStyle('min-height', '180px !important', editor.editing.view.document.getRoot());
                              writer.setStyle('margin-top', '-2px', editor.editing.view.document.getRoot());
                            });
                          }}
                        />
                        <Button className="ml-auto mt-4" type="button" variant="contained" onClick={onPostComment}>
                          Comment
                        </Button>
                      </div>
                    </div>
                  </div>
                )}
              </div>
            </section>
          </Box>
        </Fade>
      </Modal>

      <DialogSelectionFileType isEdit={isEdit} fileTypes={fileTypes} open={projectFileType} onClose={() => setProjectFileType(false)} refetch={refetchProjectDetail} />

      <BaseLocation
        open={!!openLocation && openLocation !== 'edit'}
        type={openLocation}
        defaultLocation={null}
        close={() => {
          if (!isEdit && !location) {
            setLocation(null);
          }
          setOpenLocation(false);
        }}
        action={pairLocation}
      />
    </>
  );
}

const menuTheme = createTheme({
  components: {
    MuiPopover: { styleOverrides: { root: ({ theme }) => theme.unstable_sx({}) } },
    MuiMenu: { styleOverrides: { root: ({ theme }) => theme.unstable_sx({ zIndex: 100001 }) } },
  },
});

function DialogSelectionFileType({ open, onClose, isEdit, refetch, fileTypes }) {
  const [fileType, setFileType] = useState(null);

  const {
    getRootProps,
    getInputProps,
    open: openDialog,
  } = useDropzone({
    noClick: true,
    multiple: false,
    onDrop: (acceptedFiles) => {
      if (fileType) {
        postCRMProjectAttachments({
          project: isEdit.id,
          category: fileType,
          file: acceptedFiles[0],
        })
          .then(() => {
            refetch();
            onClose();
          })
          .catch((err) => {
            console.log('err', err);
          });
      }
    },
  });

  useEffect(() => {
    if (!open) {
      setFileType(null);
    }
  }, [open]);

  return (
    <ThemeProvider theme={menuTheme}>
      <Dialog
        sx={{ zIndex: 10000, '& > .MuiDialog-container > .MuiPaper-root': { width: 480 } }}
        open={open}
        TransitionComponent={Transition}
        keepMounted
        onClose={onClose}
        aria-describedby="crm-attachments-dialog"
      >
        <DialogTitle>Category</DialogTitle>
        <DialogContent>
          <DialogContentText id="crm-attachments-dialog">
            <FormControl fullWidth sx={{ paddingTop: 1 }}>
              <InputLabel
                htmlFor="project-file-type"
                sx={{
                  top: '0px',
                  '&.Mui-focused': { top: -16, background: '#fff' },
                  '&.MuiInputLabel-shrink': { top: 8, background: '#fff' },
                }}
                shrink={!!fileType}
              >
                Profile
              </InputLabel>
              <Select
                id="project-file-type"
                value={fileType}
                required
                className="h-10"
                sx={{ width: '100%', px: 0.2, py: 0.2 }}
                onChange={(e) => setFileType(e.target.value)}
                displayEmpty
              >
                {fileTypes?.length > 0 &&
                  fileTypes?.map((p) => (
                    <MenuItem key={p.id} value={p.id}>
                      {p.name}
                    </MenuItem>
                  ))}
              </Select>
            </FormControl>
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button variant="outlined" onClick={onClose}>
            Cancel
          </Button>
          <div className="ml-2" {...getRootProps()}>
            <Button variant="contained" onClick={openDialog}>
              <input {...getInputProps()} />
              Upload
            </Button>
          </div>
        </DialogActions>
      </Dialog>
    </ThemeProvider>
  );
}
