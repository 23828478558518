import React, { useEffect, useState } from 'react';
import { AdminPanelSettings, Groups, Palette, Payment, SettingsApplications, Task, Diversity3, AcUnit, Inventory, PointOfSale } from '@mui/icons-material';
import contentRoute from '../../../router/ContentRoute';
import settingsRoute from '../../../router/SettingsRoute';
import settingsBZRoute from '../../../router/SettingsBZRoute';
import CrmRouteDynamic from '../../../router/CrmRouteDynamic';
import taskRoute from '../../../router/TaskRoute';
import accountingRoute from '../../../router/AccountingRoute';
import hrRoute from '../../../router/HRRoute';
import ProcurementRoute from '@/router/ProcurementRoute';
import ProductManagementRoute from '@/router/ProductManagementRoute';
import SalesRoute from '@/router/SalesRoute';


function SidebarItemTemp() {
  const {crmRoute, loading} = CrmRouteDynamic();

  const sideBarItemss = [
    {
      name: 'Content',
      icon: <Palette />,
      privilege: [1, 2, 3, 4, 5, 6, 7, 8, 10, 17, 15, 19],
      subscriptions: [1, 2, 5, 10],
      child: contentRoute,
    },
    {
      name: 'Settings',
      icon: <SettingsApplications />,
      privilege: [1, 2, 4, 6, 5, 7, 8, 12, 13, 15],
      subscriptions: [1, 2, 5, 8, 10, 9],
      child: settingsRoute,
    },
    {
      name: 'Settings BZ',
      icon: <AdminPanelSettings />,
      privilege: [99, 6],
      subscriptions: [1],
      child: settingsBZRoute,
    },
    {
      name: 'CRM',
      icon: <Groups />,
      privilege: [99, 7, 8, 9, 10, 17],
      subscriptions: [5],
      child: crmRoute,
    },
    {
      name: 'Task Management',
      icon: <Task />,
      privilege: [99, 12, 17],
      subscriptions: [8],
      child: taskRoute,
    },
    {
      name: 'Accounting',
      icon: <Payment />,
      privilege: [99, 13],
      subscriptions: [9],
      child: accountingRoute,
    },
    {
      name: 'HR',
      icon: <Diversity3 />,
      privilege: [99, 15, 18, 19],
      path: '/hr',
      subscriptions: [10],
      child: hrRoute,
    },

    {
      name: 'Procurement',
      icon: <AcUnit />,
      privilege: [99, 19, 20],
      path: '/procurement',
      subscriptions: [11],
      child: ProcurementRoute,
    },
    {
      name: 'Product Management',
      icon: <Inventory />,
      privilege: [1, 2, 3, 4, 5, 6, 7, 8, 10, 17, 15, 19, 20, 21, 22, 23, 24],
      path: '/procurement',
      subscriptions: [1, 2, 5, 8, 9, 10, 11, 13, 14],
      child: ProductManagementRoute,
    },
    {
      name: 'Sales',
      icon: <PointOfSale />,
      privilege: [1, 2, 3, 4, 5, 6, 7, 8, 10, 17, 15, 19, 20, 21, 22, 23, 24],
      path: '/procurement',
      subscriptions: [1, 2, 5, 8, 9, 10, 11, 13, 14],
      child: SalesRoute,
    },
  ];

  return loading ? [] : sideBarItemss;
}

export default SidebarItemTemp;