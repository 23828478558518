import React, { useCallback, useEffect, useState } from 'react';
import { Checkbox, Table, TableBody, TableCell, TableHead, TableRow, CircularProgress, Box, Paper } from '@mui/material';
import DeleteIcon from '@mui/icons-material/Delete';
import { handleModal } from '@/store/slices/modalSlice';
import { useDispatch } from 'react-redux';
import { useSelector } from 'react-redux';
import ModalWrapper from '@/components/ui/ModalWrapper';
import OutletPaymentForm from '@/components/Form/Sales/outletPaymentForm';
import POSApi from '@/api/pos/posApi';
import Swal from 'sweetalert2';

function PaymentsTab({ dataPaymentTypes, getAllPaymentTypes, outletId }) {
  const clientId = useSelector((state) => state.client.activeClient);
  const dispatch = useDispatch();

  const deleteItem = async (data) => {
    const { deletePaymentById } = POSApi();
  
    try {
      await deletePaymentById(data.id);
      getAllPaymentTypes(outletId);
      Swal.fire({
        title: 'Success!',
        text: 'Successfully Deleted Payment Method!',
        icon: 'success',
      });
    } catch (error) {
      console.error(error);
      console.log(error);
      let errorMessage = 'Error When Try to Delete Payment Method.';
      
      if (error.response?.data?.details?.some(detail => detail.includes('Order.payment_type'))) {
        errorMessage = 'The payment method is currently in use in an order!';
      }
  
      Swal.fire({
        title: 'Error!',
        text: errorMessage,
        icon: 'error',
      });
    }
  };

  const handleDeleteClick = (data) => {
    Swal.fire({
      title: 'Are you sure?',
      text: "Do you want to delete this Payment Method?",
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#2C6D47',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Yes',
      cancelButtonText: 'No'
    }).then((result) => {
      if (result.isConfirmed) {
        deleteItem(data);
      }
    });
  };

  return (
    <>
      <div className="w-full flex justify-end mb-4 items-center gap-x-2">
        <button 
          className="btn bg-[#2C6D47] p-2 text-white rounded-lg border cursor-pointer" 
          name="AddNewDiscount" 
          type="button"
          onClick={() => 
            dispatch(handleModal({
              modalId: clientId, 
              componentName: 'managePayment', 
              modalData: {
                mode: "add"
              }
            }))
          }  
        >
          Add New Type
        </button>
      </div>
      <Box sx={{ width: '100%' }}>
        <Paper sx={{ width: '100%', mb: 2 }}>
          <Table>
            <TableHead>
              <TableRow>
                <TableCell align="center" className="!font-bold">
                  Name
                </TableCell>
                <TableCell align="center" className="!font-bold" width={300}>
                  Allow Top Up
                </TableCell>
                <TableCell align="center" className="!font-bold" width={300}>
                  Include In Sales
                </TableCell>
                <TableCell align="center" className="!font-bold" width={100} />
              </TableRow>
            </TableHead>
            <TableBody>
              {dataPaymentTypes.length > 0 &&
                dataPaymentTypes.map((coll, idx) => {
                  return (
                    <TableRow>
                      <TableCell align="left">
                        <div
                          className="w-fit cursor-pointer text-[#2C6D47] font-semibold"
                          onClick={() => 
                            dispatch(handleModal({
                              modalId: clientId, 
                              componentName: 'managePayment', 
                              modalData: { 
                                mode: "edit",
                                data: coll
                              }
                            }))
                          }
                        >
                          {coll?.name || '-'}
                        </div>
                      </TableCell>
                      <TableCell align="left">{coll?.allow_in_top_up ? "true" : "false"}</TableCell>
                      <TableCell align="left">{coll?.include_in_sales ? "true" : "false"}</TableCell>
                      <TableCell align="center">
                        <DeleteIcon className='cursor-pointer' onClick={() => handleDeleteClick(coll)} />
                      </TableCell>
                    </TableRow>
                  );
                })}
            </TableBody>
          </Table>
        </Paper>
      </Box>
      <ModalWrapper componentName="managePayment" header="Manage Payment" maxWidth="sm">
        <OutletPaymentForm getAllPaymentTypes={getAllPaymentTypes} outletId={outletId} />
      </ModalWrapper>
    </>
  );
}

export default PaymentsTab;
