/* eslint-disable no-unused-vars */
import React, { useState, useEffect, useLayoutEffect, useCallback } from 'react';
import { AssignmentInd, AutoGraph, Circle, Dashboard, Group, HeadsetMic, Layers, Paid, Public } from '@mui/icons-material';
import { useSelector } from 'react-redux';

import Customer from '../pages/CRM/Customer';
import CustomerDetail from '../pages/CRM/CustomerDetail';
import CRMProject from '../pages/CRM/Project/List';
import CRMProjectAdd from '../pages/CRM/Project/Add';
import CRMProjectDetail from '../pages/CRM/Project/Detail';
import DetailProfile from '../pages/CRM/Project/DetailProfile';
import NewProfile from '../pages/CRM/Project/NewProfile';
import Reports from '../pages/CRM/Reports';
import ReportsDashboard from '../pages/CRM/Reports/Dashboard3';
import ReportsCountries from '../pages/CRM/Reports/Countries';
import CRMApi from '../api/CRM/CRMCustomers';

const CrmRouteDynamic = () => {
  const mode = import.meta.env.VITE_MODE;
  const crmProjectsRoute = mode === 'development' ? [61, 3] : [32, 3];
  const [loading, setLoading] = useState(true);
  const [crmRoute, setCrmRoute] = useState([]);
  const client = useSelector((state) => state.client.activeClient);

  const getSectionDetail = useCallback(async () => {
    const { getReportGroup } = CRMApi();
  
    const routes = [
      {
        name: 'Customers',
        element: <Customer />,
        icon: <Circle />,
        privilege: [99, 5, 7, 8, 9, 10, 17],
        subscriptions: [5],
        path: '/customers',
        isSidebar: true,
      },
      {
        name: 'Customer Add',
        element: <CustomerDetail />,
        privilege: [99, 7, 8, 9, 10, 17],
        subscriptions: [5],
        path: '/customers/add',
      },
      {
        name: 'Customer Detail',
        element: <CustomerDetail />,
        privilege: [99, 7, 8, 9, 10, 17],
        subscriptions: [5],
        path: '/customers/detail/:customerId',
      },
      {
        name: 'Projects',
        element: <CRMProject />,
        icon: <Circle />,
        privilege: [99, 5, 7, 8, 9, 10, 17],
        subscriptions: [5],
        client: crmProjectsRoute,
        path: '/crm/projects/',
        isSidebar: true,
      },
      {
        name: 'Projects',
        element: <CRMProject />,
        icon: <Circle />,
        privilege: [99, 5, 7, 8, 9, 10, 17],
        subscriptions: [5],
        client: crmProjectsRoute,
        path: '/crm/projects/:tabName',
        isSidebar: false,
      },
      {
        name: 'CRM Projects Add',
        element: <CRMProjectAdd />,
        icon: <Circle />,
        privilege: [99, 5, 7, 8, 9, 10, 17],
        subscriptions: [5],
        client: crmProjectsRoute,
        path: '/crm/projects/add',
        isSidebar: false,
      },
      {
        name: 'CRM Projects Detail',
        element: <CRMProjectDetail />,
        icon: <Circle />,
        privilege: [99, 5, 7, 8, 9, 10, 17],
        subscriptions: [5],
        client: crmProjectsRoute,
        path: '/crm/projects/detail/:projectId',
        isSidebar: false,
      },
      {
        name: 'CRM Projects Profile Add',
        element: <NewProfile />,
        icon: <Circle />,
        privilege: [99, 5, 7, 8, 9, 10, 17],
        subscriptions: [5],
        client: crmProjectsRoute,
        path: '/crm/profile/add',
        isSidebar: false,
      },
      {
        name: 'CRM Projects Profile Detail',
        element: <DetailProfile />,
        icon: <Circle />,
        privilege: [99, 5, 7, 8, 10],
        subscriptions: [5],
        client: crmProjectsRoute,
        path: '/crm/profile/detail/:profileId',
        isSidebar: false,
      },
    ];
  
    if (!client) {
      // setCrmRoute(routes);
      // setLoading(false);
      return;
    }
  
    try {
      const fetchData = await getReportGroup(client);
      const response = fetchData.results;
  
      const newDynamicReport = response.map((report) => ({
        name: report.name,
        element: report.name !== "Dashboard" ? <ReportsCountries /> : <ReportsDashboard attributeReports={[]} />,
        icon: <Group />,
        privilege: [99, 5, 7, 8, 9, 10, 17],
        subscriptions: [5],
        path: `/reports/${report.name.toLowerCase().replace(/\s/g, '-')}`,
        isSidebar: true,
      }));
  
      const updatedCrmRoute = response.length > 0 ? [
        ...routes,
        {
          name: 'Reports',
          element: <Reports />,
          icon: <AutoGraph />,
          privilege: [99, 5, 7, 8, 9, 10, 17],
          subscriptions: [5],
          client: [...crmProjectsRoute, 15],
          path: '/reports',
          child: newDynamicReport,
          isSidebar: true,
        },
      ] : routes;
  
      setCrmRoute(updatedCrmRoute);
      setLoading(false);
    } catch (error) {
      console.error(error);
    }
  }, [client]);
  

  useEffect(() => {
    setCrmRoute([]);
    getSectionDetail()
  }, [client]);

  return { crmRoute, loading };
};

export default CrmRouteDynamic;
