/* eslint-disable */
import React, { useEffect, useState } from 'react';
import _ from 'lodash';
import { Button } from '@mui/material';
import * as yup from 'yup';
import { useSelector, useDispatch } from 'react-redux';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import Select, { components } from 'react-select';
import { GoogleMap, Marker, Autocomplete } from "@react-google-maps/api";
import { handleModal } from '../../../store/slices/modalSlice';
import CRMApi from '../../../api/CRM/CRMCustomers';
import ReactSelectMultiple from '../../Input/ReactSelectMultiple';
import swal from 'sweetalert';

const schema = yup.object().shape({
  name: yup.string().required("location name cannot be empty"),
  address: yup.string().required("location address cannot be empty"),
});

function AddNewLocation({ backtoLocationPair }) {
  const dispatch = useDispatch()

  const {
    register,
    handleSubmit,
    control,
    setValue,
    formState: { errors },
  } = useForm({ resolver: yupResolver(schema) });

  const activeClient = useSelector((state) => state.modal.modalId);
  const memberId = useSelector((state) => state.modal.modalData);
  const [allCountries, setAllCountries] = useState([])
  const [selectedCountry, setSelectedCountry] = useState({})
  const [address, setAddress] = useState('')
  const [name, setName] = useState('')
  const [latitude, setLatitude] = useState(-6.1741)
  const [longitude, setLongitude] = useState(106.8296)
  const [onLoad, setOnLoad] = useState(null)

  const containerStyle = {
    width: "100%",
    height: "100%",
  };

  const center = {
    lat: parseFloat(latitude),
    lng: parseFloat(longitude),
  };

  const getAllCountries = async () => {
    const { getCountries } = CRMApi();

    try {
      const fetchData = await getCountries({ max_size: true });
      const response = await fetchData.data.results;
      const countriesData = response;

      setAllCountries(countriesData)
    } catch (error) {
      console.error(error);
    }
  };

  const handleChangeMarker = (event) => {
    setLatitude(event.latLng.lat())
    setLongitude(event.latLng.lng())
  }

  useEffect(() => {
    getAllCountries()
  }, [])

  const handleChangePlace = (place) => {
    place = onLoad.getPlace()

    let selectedCountry = {}
    const addressComponents = place?.address_components;

    if (addressComponents) {
      const countriesName = allCountries.map(country => country.iso_code)
      const addressCountry = addressComponents.map(address => address.short_name)
      const foundedCountry = _.intersection(countriesName, addressCountry)
      const countryCode = foundedCountry.length ? foundedCountry[0] : ""
      selectedCountry = allCountries.find(({ iso_code }) => iso_code === countryCode);
    }

    setSelectedCountry(selectedCountry)
    setValue("address", place?.formatted_address)
    setAddress(place?.formatted_address)
    setLatitude(place?.geometry.location.lat())
    setLongitude(place?.geometry.location.lng())
  }

  const createLocation = (dt) => {
    const { addLocation } = CRMApi();

    const data = {
      client: activeClient,
      country: selectedCountry.id,
      name: dt?.name,
      address: dt?.address,
      latitude: latitude,
      longitude: longitude,
      is_main: false,
    }

    try {
      addLocation(data)
      .then((res) => {
        if (res.status === 201 || res.data) {
          swal('New Location Added Successfully!', { icon: 'success' });
          return backtoLocationPair()
        }
      })
      .catch((err) => {
        console.error('error', err);
      });
    } catch (error) {
      console.error(error);
    }
  }

  return (
    <form onSubmit={handleSubmit(createLocation)}>
      <div className="" style={{ height: "200px" }}>
        <GoogleMap
          mapContainerStyle={containerStyle}
          center={center}
          zoom={15}
          mapTypeId="roadmap"
          onLoad={(e) => setOnLoad(e)}
          onRightClick={(e) => handleChangeMarker(e)}
        >
          <Autocomplete
            className="z-20"
            onLoad={(e) => setOnLoad(e)}
            onPlaceChanged={(e) => handleChangePlace(e)}
            >
            <input
              className="border border-transparent shadow-sm text-sm text-ellipsis z-20 w-[50%] h-[2.5rem] rounded box-border px-[12px] absolute top-[.6rem] left-[40%]"
              id="loc-search-place"
              type="text"
              placeholder="Search place"
            />
          </Autocomplete>
          <Marker position={center} />
        </GoogleMap>
      </div>
      <div className="mt-4 space-y-2">
        <div className="space-y-2">
          <div>Name</div>
          <input
            id="create-location-name"
            type="text"
            name="name"
            {...register(`name`)}
            className="w-full border rounded-md shadow-sm p-2"
            value={name}
            onChange={(e) => setName(e.target.value) }
          />
        </div>
        {errors &&
          (Array.isArray(errors["name"]?.message) ? (
            errors["name"]?.message.map(m => <span className="text-red-600">{m.toLowerCase()}</span>)
          ) : (
            <span className="text-red-600">
              {errors["name"]?.message.replace("name", "name".toLowerCase())}
            </span>
          ))
        }
        <div className="space-y-2">
          <div>Address</div>
          <textarea
            id="create-location-address"
            type="text"
            name="address"
            {...register(`address`)}
            className="w-full h-[60px] border rounded-md shadow-sm p-2"
            // value={address}
            onChange={(e) => setAddress(e.target.value) }
          />
          {errors &&
            (Array.isArray(errors["address"]?.message) ? (
              errors["address"]?.message.map(m => <span className="text-red-600">{m.toLowerCase()}</span>)
            ) : (
              <span className="text-red-600">
                {errors["address"]?.message.replace("address", "address".toLowerCase())}
              </span>
            ))
          }
        </div>
        <div className="space-y-2">
          <div>Country</div>
            {/* <ReactSelectMultiple
              options={allCountries?.map((item) => ({ label: item.name, value: item.id }))}
              defaultValue={country}
              value={country}
              placeholder="-- select country --"
              onChange={(v) => {
                setCountry(v)
              }}
              className="w-full"
            /> */}
            <Select
              options={allCountries?.map((item) => ({ label: item.name, value: item.id }))}
              value={!selectedCountry.length ? { label: selectedCountry.name, value: selectedCountry.id } : []}
              isSearchable={false}
              placeholder="-- select country --"
              menuPosition="fixed"
              menuPlacement="auto"
              onChange={(v) => {
                const selected = allCountries.find(({ id }) => id === parseInt(v.value));
                setSelectedCountry(selected)
              }}
              styles={{
                control: (provided) => ({
                  ...provided,
                  minHeight: '2.5rem',
                }),
                container: (provided) => ({
                  ...provided,
                }),
                valueContainer: (provided) => ({
                  ...provided,
                  overflow: 'visible',
                  display: 'flex',
                  alignItems: 'center',
                }),
                placeholder: (provided, state) => ({
                  ...provided,
                  position: 'absolute',
                  top: state.hasValue || state.selectProps.inputValue ? -13 : '20%',
                  transition: 'top 0.1s, font-size 0.1s',
                  fontSize: (state.hasValue || state.selectProps.inputValue) && 13,
                  background: 'white',
                  margin: '0px 20',
                }),
                menuPortal: (provided) => ({
                  ...provided,
                  zIndex: 10000,
                  marginTop: "-10px"
                }),
              }}
            />
        </div>
      </div>
      <div className="w-full flex justify-end mt-4 pt-4 border-t">
        <button
          className=" bg-[#2C6D47] p-2 text-white rounded-lg"
          type="submit"
          // variant="contained"
          // onClick={() => {
          //   createLocation()
          //   }}
          >
          Submit
        </button>
      </div>
    </form>
  );
}

export default AddNewLocation;
