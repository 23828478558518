import React from 'react';
import { Person, Category, Info, Mail, BarChart, Translate, Speed } from '@mui/icons-material';

import User from '../pages/Settings/User';
import UserDetail from '../pages/Settings/UserDetail';
import CategoryAsset from '../pages/Settings/AssetCategory/Category';
import AboutUs from '../pages/Settings/AbousUs';
import Email from '../pages/Settings/Email';
import Sitemap from '../pages/Settings/Sitemap';
import Language from '../pages/Settings/Language';
import SitePerformances from '../pages/Settings/SitePerformances';
import UserAdd from '../pages/Settings/UserAdd';
import BusinessUnitList from '../pages/Settings/BusinessUnit/BusinessUnitList';
import UnitDetail from '../pages/Settings/BusinessUnit/UnitDetail';
import UnitDetailSetting from '@/pages/Settings/UnitDetailSetting';
import UnitAdd from '@/pages/HR/BusinessUnit/UnitAdd';

const settingsRoute = [
  {
    name: 'User',
    element: <User />,
    icon: <Person />,
    privilege: [4, 5, 6, 7, 8, 19],
    subscriptions: [1, 2, 5, 8, 10, 11],
    path: '/user',
    isSidebar: true,
  },
  {
    name: 'User Detail',
    element: <UserDetail />,
    privilege: [3, 4, 5, 6, 7, 1, 2, 5, 8, 10, 12, 13, 17, 19, 20],
    subscriptions: [1, 2, 5, 8, 10, 11],
    path: '/user/detail/:userId',
  },
  {
    name: 'User Add',
    element: <UserAdd />,
    privilege: [4, 5, 6, 7, 8, 19],
    subscriptions: [1, 2, 5, 8, 10, 11],
    path: '/user/add',
  },
  // {
  //   name: 'User Detail',
  //   element: <UserDetail />,
  //   privilege: [1, 2, 3, 4, 5, 6, 7, 8],
  //   subscriptions: [1, 2, 5, 8, 10],
  //   path: '/user/detail/:employeeId',
  // },

  {
    name: 'Asset Category',
    element: <CategoryAsset />,
    icon: <Category />,
    privilege: [1, 2, 4, 6],
    subscriptions: [1],
    path: '/asset/category',
    isSidebar: true,
  },
  {
    name: 'About us',
    element: <AboutUs />,
    icon: <Info />,
    privilege: [1, 2, 4, 6],
    subscriptions: [1],
    path: '/about',
    isSidebar: true,
  },
  {
    name: 'Email Settings',
    element: <Email />,
    icon: <Mail />,
    privilege: [1, 2, 4, 6],
    subscriptions: [1],
    path: '/email-settings',
    isSidebar: true,
  },
  {
    name: 'Sitemap Settings',
    element: <Sitemap />,
    icon: <BarChart />,
    privilege: [4, 6],
    subscriptions: [1],
    path: '/sitemap-settings',
    isSidebar: true,
  },
  {
    name: 'Language',
    element: <Language />,
    icon: <Translate />,
    privilege: [1, 2, 4, 5, 6],
    subscriptions: [1, 2],
    path: '/language',
    isSidebar: true,
  },
  {
    name: 'Site Performances',
    element: <SitePerformances />,
    icon: <Speed />,
    privilege: [99, 12],
    subscriptions: [1, 2],
    path: '/site-performances',
    isSidebar: true,
  },
  {
    name: 'Business Units',
    element: <BusinessUnitList />,
    icon: <Speed />,
    privilege: [99, 12, 13, 14, 19, 20],
    subscriptions: [1, 2, 9, 11],
    path: '/settings/business-units',
    isSidebar: true,
  },
  {
    name: 'Business Units',
    element: <UnitAdd />,
    icon: <Speed />,
    privilege: [99, 12],
    subscriptions: [2, 9, 5],
    path: '/settings/business-units/add',
    isSidebar: false,
  },
  // {
  //   name: 'Business Units',
  //   element: <UnitAdd />,
  //   icon: <Speed />,
  //   privilege: [99, 12],
  //   subscriptions: [2, 9, 5],
  //   path: '/settings/business-units/:',
  //   isSidebar: false,
  // },

  {
    name: 'Business Unit',
    element: <UnitDetailSetting />,
    icon: <Speed />,
  privilege: [99, 12, 13, 14, 19, 20],
    subscriptions: [1, 2, 9],
    path: '/settings/business-units/:businessUnitId',
    isSidebar: false,
  },
  // {
  //   name: 'Business Unit',
  //   element: <UnitDetail />,
  //   icon: <Speed />,
  //   privilege: [99, 12, 13],
  //   subscriptions: [2, 9, 5],
  //   path: '/settings/business-unit/:businessUnitId',
  //   isSidebar: false,
  // },
  {
    name: 'Business Unit',
    element: <UnitDetail />,
    icon: <Speed />,
    privilege: [99, 12, 13, 14, 19, 20],
    subscriptions: [1, 2, 9],
    path: '/settings/business-unit/:businessUnitId',
    isSidebar: false,
  },
];

export default settingsRoute;
