import React, { useEffect } from 'react';
import { Controller } from 'react-hook-form';
import { TextField, InputAdornment, IconButton } from '@mui/material';
import Visibility from '@mui/icons-material/Visibility';
import VisibilityOff from '@mui/icons-material/VisibilityOff';

function InputContainer({
  name,
  control,
  type = 'text',
  label,
  errors,
  className,
  defaultValue = '',
  hidden = false,
  disabled = false,
  readOnly = false,
  showPass,
  showCfPass,
  handleClickShowPassword,
  config = {},
  onFocus,
  onChangeValue,
  placeholder,
  autoFocus = false,
  rows,
  setValue,
}) {
  useEffect(() => {
    if (setValue) {
      setValue();
    }
  }, [setValue]);

  const renderTextField = (field) => (
    <TextField
      fullWidth
      autoFocus={autoFocus}
      className={className}
      size="small"
      sx={{ textTransform: 'capitalize', marginTop: '4px', background: 'white' }}
      placeholder={placeholder}
      onFocus={onFocus}
      hidden={hidden}
      disabled={disabled}
      multiline={Boolean(rows)}
      rows={rows}
      margin="normal"
      error={Boolean(errors)}
      helperText={errors?.message}
      type={type === 'password' ? (showPass ?? showCfPass ? 'text' : 'password') : type}
      InputProps={{
        readOnly,
        autoComplete: 'new-password',
        endAdornment: type === 'password' && (
          <InputAdornment position="end">
            <IconButton aria-label="toggle password visibility" onClick={handleClickShowPassword} edge="end">
              {showPass ?? showCfPass ? <VisibilityOff /> : <Visibility />}
            </IconButton>
          </InputAdornment>
        ),
      }}
      onChange={(event) => {
        field.onChange(event.target.value);
        onChangeValue && onChangeValue(event.target.value);
      }}
      {...field}
      {...config}
    />
  );

  return (
    <div className={`z-index-label ${className} ${hidden && 'hidden'}`}>
      <Controller
        name={name}
        control={control}
        defaultValue={defaultValue}
        render={({ field }) => renderTextField(field)}
      />
    </div>
  );
}

export default InputContainer;