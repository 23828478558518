import React, { createContext, useState, useMemo, useCallback, useEffect, useLayoutEffect } from 'react';
import { useForm, Controller } from 'react-hook-form';
import { useSearchParams, Link, useParams, useNavigate } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';
import {  CircularProgress } from '@mui/material'
import ProductManagementApi from '@/api/ProductManagement/ProductManagementApi'
import BZHelmet from '@/utils/BZHelmet';
import BaseMainTitle from '@/components/BaseMainTitle';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import Box from '@mui/material/Box';
import { ArrowBack } from '@mui/icons-material';
import _ from 'lodash';

import HrApi from '@/api/HR/api';
import ProcurementApi from '@/api/Procurement/Procurement'
import AccountingApi from '@/api/accounting/accounting'
import { fetchData } from '@/utils/fetchDataFunc';
import ProductContext from './ProductContext';
import ProductDetailTabNew from './productDetailComponents/ProductDetailTabNew';
import PricingManagerTab from './productDetailComponents/PricingManagerTab';

function ProductDetails() {
  const { register, handleSubmit, control, errors, setValue } = useForm();
  const clientId = useSelector((state) => state.client.activeClient);
  const navigate = useNavigate();
  const { productId, vendorId } = useParams();
  const isFromProcurement = vendorId !== undefined;
  const params = useParams();
  
  const dispatch = useDispatch();

  // ALL BEHAVIORS
  const [isFetching, setIsFetching] = useState(productId === undefined ? false : true);

  const [isInternal, setIsInternal] = useState(null);
  const [sellableProduct, setSellableProduct] = useState(null);

  // ALL PRODUCT DATA
  const [productData, setProductData] = useState();

  // PRODUCT MEDIA
  const [productFiles, setProductFiles] = useState([]);
  const [productFilesOnDelete, setProductFilesOnDelete] = useState([]);
  const [productMedia, setProductMedia] = useState([]);
  const [productMediaShow, setProductMediaShow] = useState([]);  

  // PRODUCT COMPONENTS
  const [productComponents, setProductComponents] = useState([]);
  const [editableCost, setEditableCost] = useState({contingency_cost: "", overhead_cost: "", profit_value: ""});

  // ALL OPTIONS
  const [categoriesOptions, setCategoriesOptions] = useState([]);
  const [typesOptions, setTypesOptions] = useState([]);
  const [collectionsOptions, setCollectionsOptions] = useState([]);
  const [departmentOptions, setDepartmentOptions] = useState([]);
  const [vendorOptions, setVendorOptions] = useState([]);
  const [currencyOptions, setCurrencyOptions] = useState([]);
  const [productUnitOptions, setProductUnitOptions] = useState([]);
  const [isLoadingOptions, setIsLoadingOptions] = useState(true)

  // PRODUCT SELLING PRICE
  const [productSellingPrice, setSellingPrice] = useState("");
  const [productGrandTotal, setGrandTotal] = useState("");
  const [basicPrice, setBasicPrice] = useState("");
  
  // PRODUCT UNIT SIZE 
  const [sizeUnit, setSizeUnit] = useState('');
  const [dimensions, setDimensions] = useState([{ id: 1, type: '', value: null }]);

  // MODIFIERS
  const [addOns, setAddOns] = useState([]);
  const [productComponentsModifiers, setProductComponentsModifiers] = useState([]);

  const [productVendors, setProductVendors] = useState([]);

  const [marketTabValue, setMarketTabValue] = useState("");
  const [insideMarketTabValue, setInsideMarketTabValue] = useState(0);
  const [marketManagers, setMarketManagers] = useState([]);  
  const [pricingManagers, setPricingManagers] = useState([]);  
  const [pricingManagersSplit, setPricingManagersSplit] = useState(null);
  const [listOfRecentlyUpdate, setListOfRecentlyUpdate] = useState([]);
  const [addNew, setAddNew] = useState(false);
  
  const [pricingProjection, setPricingProjection] = useState(null);
  const [isFetchingProjection, setIsFetchingProjection] = useState(true);
  
  const [costBreakdown, setCostBreakDown] = useState(null)
  const [isFetchingCostBreakdown, setIsFetchingCostBreakdown] = useState(true);
  const [selectedInput, setSelectedInput] = useState([]);
  const [isAllSelectedEmployees, setIsAllSelectedEmployees] = useState(false);
  const [newDimensions, setNewDimensions] = useState([]);
  const [startPeriod, setStartPeriod] = useState(null);
  // const [pricingManagerCurr, setPricingManagerCurr] = useState(null)

  const [mode, setMode] = useState('');
  useEffect(() => {
    if (productId === undefined) {
      setMode('add');
    } else {
      setMode('edit');
    }
  }, [productId]);

  useLayoutEffect(() => {
    setProductData()
  }, [productId]);

  useEffect(() => {
    const handlePopState = (event) => {
      if (location.state && location.state.fromBackButton) {
        navigate('/product-management/products');
      }
    };

    window.addEventListener('popstate', handlePopState);

    return () => {
      window.removeEventListener('popstate', handlePopState);
    };
  }, [location, navigate]);

  const productDetail = useCallback(async () => {
    const { getProductDetail } = ProductManagementApi();

    try {
      const getData = await getProductDetail(productId);
      const data = await getData.data;
      
      if(data?.components.length > 0) {
        setProductComponents(data?.components);
      }

      if(data?.add_ons.length > 0) {
        setAddOns(data?.add_ons);
      }

      if(data?.item_size !== null) {
        setSizeUnit(data?.item_size?.unit);
        setDimensions(data?.item_size?.dimensions);
      } else {
        setSizeUnit('');
        setDimensions([{ id: 1, type: '', value: null }]);
      }
      setProductVendors(data.vendors)
      setSellingPrice(data?.selling_price);
      setBasicPrice(data?.price || "0");
      setGrandTotal(data?.components.length > 0 ? data?.grand_total : "0");
      setEditableCost({
        contingency_cost: data?.contingency_cost || "",
        overhead_cost: data?.overhead_cost || "",
        profit_value: data?.profit_value || ""
      })
      setIsInternal(data?.internal_product)
      setSellableProduct(data?.sellable_product)
      setProductData(data)
      setProductFiles(data?.files)
      if (data?.media.length > 0){
        setProductMedia(data?.media)
        setProductMediaShow([data?.media[data?.media.length - 1]])
      } else {
        setProductMedia([])
        setProductMediaShow([])
      }

      // Update form values with setValue
      setValue("item_number", data?.item_number || "");
      setValue("name", data?.name || "");
      setValue("unit", data?.unit || "");
      setValue("currency", data?.currency || "");
      setValue("category", data?.category?.id || "");
      setValue("collections", data?.collections.map((dt) => dt.id) || []);
      setValue("department", data?.department?.id || "");
      setValue("type", data?.type?.id || "");
      setValue("vendor", data?.vendors.map((item) => ({ label: item.name, value: item.id })) || "");
      setValue("sellable_product", data?.sellable_product || false);
      setValue("internal_product", data?.internal_product || false);
      setValue("item_description", data?.item_description || "");

      setIsFetching(false)
    } catch (error) {
      console.error(error);
    }
  }, [productId]);

  const categories = useCallback((params) => fetchData(ProductManagementApi().getCategories), [clientId]);
  const getProductUnitList = useCallback((params) => fetchData(ProductManagementApi().getProductUnit ), [clientId]);
  const getCurrencyList = useCallback((params = { client:  clientId }) => fetchData(AccountingApi().getCurrencies, params ), [clientId]);
  const collections = useCallback((params = { max_size: true, client: clientId }) => fetchData(ProductManagementApi().getCollections, params ), [clientId]);
  const departments = useCallback((params = { max_size: true, client: clientId }) => fetchData(HrApi().getDepartment, params ), [clientId]);
  const types = useCallback((params = { max_size: true, client: clientId }) => fetchData(ProductManagementApi().getTypes, params ), [clientId]);
  const vendors = useCallback((params = { max_size: true, client: clientId }) => fetchData(ProcurementApi().getAllMembers, params ), [clientId]);

  const getAllOptions = useCallback(async () => {
    const [categoryList, productList, currecyList, collectionsList, departmentList, typeList, vendorList] = await Promise.all([
      categories(), 
      getProductUnitList(),
      getCurrencyList(),
      collections(),
      departments(),
      types(),
      vendors(),
    ]);
    if (categoryList && productList && currecyList && collectionsList && departmentList && typeList && vendorList) {
      setCategoriesOptions(categoryList.results);
      setProductUnitOptions(productList.results);
      setCurrencyOptions(currecyList.results);
      setCollectionsOptions(collectionsList.results);
      setDepartmentOptions(departmentList.results);
      setTypesOptions(typeList.results);
      setVendorOptions(vendorList.results);

      setIsLoadingOptions(false);
    }
  }, [categories, getProductUnitList, getCurrencyList, collections, departments, types, vendors]);

  useEffect(() => {
    if (productId !== undefined) {
      productDetail();
    }

  }, [productId, productDetail])

  useEffect(() => {
    getAllOptions()
  }, [productId, clientId, getAllOptions]);

  /**
   * PRICING MANAGER
   */

  const markets = useCallback((params = { departments__client: clientId }) => fetchData(ProductManagementApi().getMarketManagers, params), [clientId]);
  const fetchMarketManagers = useCallback(async (params) => {
    const [marketManagers] = await Promise.all([ markets(params) ]);
    if (marketManagers) {
      setMarketManagers(marketManagers.results)
      setMarketTabValue(marketManagers.results[0]?.market_name)
    }
  }, [markets, clientId]);
  
  const pricings = useCallback((params) => fetchData(ProductManagementApi().getProductPricingManagers, params), [productId]);
  const fetchPricingManagers = useCallback(async (params) => {
    const [pricingManagers] = await Promise.all([pricings(params)]);
  
    if (pricingManagers) {
      setPricingManagers(pricingManagers?.results);
  
      const { results } = pricingManagers;
  
      let splitByMarketName = {};
  
      results.forEach((dt) => {
        const { market_name } = dt?.market_manager;
        if (!splitByMarketName[market_name]) {
          splitByMarketName[market_name] = [];
        }
        splitByMarketName[market_name].push(dt);
      });

      setPricingManagersSplit(splitByMarketName);
    }
  }, [pricings]);

  useEffect(() => {
    fetchMarketManagers({departments__client: clientId })
  }, [clientId])
  
  const getPricingCostBreakdown = useCallback((params) => fetchData(ProductManagementApi().getCostBreakDown, params), [productId]);
  const fetchCostBreakdown = useCallback(async (params) => {
    const [cost] = await Promise.all([getPricingCostBreakdown(params)]);
  
    if (cost) {
      setCostBreakDown(cost);
      setIsFetchingCostBreakdown(false);
    }
  }, [getPricingCostBreakdown]);

  useEffect(() => {
    if (marketTabValue !== "") {
      setIsFetchingCostBreakdown(true);
      const marketId = marketManagers.find(dt => dt.market_name === marketTabValue);
      fetchCostBreakdown({product_id: productId, currency_id: 1, market_id: marketId.id})
    }
  }, [marketTabValue, productId])

  useEffect(() => {
    if(productId !== undefined) {
      fetchPricingManagers({product: productId, ordering: "-start_date"})
    }
  }, [productId]);
  
  const getProjection = useCallback((params) => fetchData(ProductManagementApi().getPricingProjection, params), [productId]);
  const fetchPricingProjection = useCallback(async (params) => {
    const [projection] = await Promise.all([getProjection(params)]);
  
    if (projection) {
      setPricingProjection(projection);
      setIsFetchingProjection(false);
    }
  }, [getProjection]);

  useEffect(() => {
    if (marketTabValue !== "") {
      setIsFetchingProjection(true);
      const marketId = marketManagers.find(dt => dt.market_name === marketTabValue);
      fetchPricingProjection({product_id: productId, currency_id: 1, market_id: marketId.id})
    }
  }, [marketTabValue, productId])

  const getAllPricingManagerData = async ({currency = null, start_date = null, onlyPricingManagers = false}) => {
    const marketId = marketManagers.find(dt => dt.market_name === marketTabValue);
    
    if (!marketId) {
      console.error("Market ID not found");
      return;
    }
  
    try {
      if (onlyPricingManagers) {
        await fetchPricingManagers({product: productId, ordering: "-start_date", start_date: start_date || ""});
      } else {
        setIsFetchingProjection(true);
        setIsFetchingCostBreakdown(true);
  
        await Promise.all([
          fetchPricingProjection({product_id: productId, currency_id: currency || 1, market_id: marketId.id}),
          fetchCostBreakdown({product_id: productId, currency_id: currency || 1, market_id: marketId.id}),
          fetchPricingManagers({product: productId, ordering: "-start_date", start_date: start_date || ""})
        ]);
      }
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };

  const contextValue = useMemo(() => ({
    isFetching,
    setIsFetching,
    isInternal,
    setIsInternal,
    sellableProduct,
    setSellableProduct,
    productData,
    setProductData,
    productFiles,
    setProductFiles,
    productFilesOnDelete,
    setProductFilesOnDelete,
    productMedia,
    setProductMedia,
    productMediaShow,
    setProductMediaShow,
    productComponents,
    setProductComponents,
    editableCost,
    setEditableCost,
    categoriesOptions,
    setCategoriesOptions,
    typesOptions,
    setTypesOptions,
    collectionsOptions,
    setCollectionsOptions,
    departmentOptions,
    setDepartmentOptions,
    vendorOptions,
    setVendorOptions,
    currencyOptions,
    setCurrencyOptions,
    productUnitOptions,
    setProductUnitOptions,
    isLoadingOptions,
    setIsLoadingOptions,
    productSellingPrice,
    setSellingPrice,
    productGrandTotal,
    setGrandTotal,
    basicPrice,
    setBasicPrice,
    sizeUnit,
    setSizeUnit,
    dimensions,
    setDimensions,
    addOns,
    setAddOns,
    productComponentsModifiers,
    setProductComponentsModifiers,
    productVendors,
    setProductVendors,
    mode,
    setMode,
    marketTabValue,
    setMarketTabValue,
    insideMarketTabValue,
    setInsideMarketTabValue,
    marketManagers,
    setMarketManagers,
    pricingManagers,
    setPricingManagers,
    pricingManagersSplit,
    setPricingManagersSplit,
    pricingProjection,
    setPricingProjection,
    isFetchingProjection,
    setIsFetchingProjection,
    isFetchingCostBreakdown,
    setIsFetchingCostBreakdown,
    costBreakdown,
    setCostBreakDown,
    listOfRecentlyUpdate,
    setListOfRecentlyUpdate,
    addNew,
    setAddNew,
    selectedInput,
    setSelectedInput,
    isAllSelectedEmployees,
    setIsAllSelectedEmployees,
    newDimensions,
    setNewDimensions,
    startPeriod,
    setStartPeriod,
    fetchPricingManagers,
    getAllPricingManagerData
  }), [
    open,
    isFetching,
    isInternal,
    sellableProduct,
    productData,
    productFiles,
    productFilesOnDelete,
    productMedia,
    productMediaShow,
    productComponents,
    editableCost,
    categoriesOptions,
    typesOptions,
    collectionsOptions,
    departmentOptions,
    vendorOptions,
    currencyOptions,
    productUnitOptions,
    isLoadingOptions,
    productSellingPrice,
    productGrandTotal,
    basicPrice,
    sizeUnit,
    dimensions,
    addOns,
    productComponentsModifiers,
    productVendors,
    mode,
    marketTabValue,
    insideMarketTabValue,
    marketManagers,
    pricingManagers,
    pricingManagersSplit,
    pricingProjection,
    isFetchingProjection,
    isFetchingCostBreakdown,
    costBreakdown,
    listOfRecentlyUpdate,
    addNew,
    selectedInput,
    isAllSelectedEmployees,
    newDimensions,
    startPeriod,
    fetchPricingManagers
  ]);
  

  const handleChangePage = () => {
    const lastPage = sessionStorage.getItem('lastPage');
    
    if (lastPage === 'add') {
      navigate('/product-management/products');
      sessionStorage.removeItem('lastPage');
    } else {
      if(isFromProcurement) {
        navigate(`/vendor/detail/${vendorId}`);
      } else {
        navigate(`/product-management/products`);
      }
    }
  };

  const [tabValue, setTabValue] = useState(0);
  const [mainTabValue, setMainTabValue] = useState(0);

  const handleChangeMainTabs = (_, newValue) => {
    setMainTabValue(newValue);
  };

  return (
    <ProductContext.Provider value={contextValue}>
      <BZHelmet title={mode === "add" ? "Add Product" : "Product Detail"} description="" content="" />
      <BaseMainTitle title={mode === "add" ? "Add Product" : "Product Detail"} />
      {
        !isFetching ? (
          <div className="p-5 space-y-6">
            <button
              className="bg-[#2C6D47] flex h-[40px] gap-x-2 items-center text-center btn btn-primary rounded-xl border-0 px-4 text-white"
              type="text"
              name="Back to list"
              onClick={handleChangePage}
            >
              <ArrowBack />
              {isFromProcurement ? "Back To Vendor Detail" : "Back To List"}
            </button>
            <Box sx={{ width: '100%', position: 'relative' }}>
              <Box sx={{ marginBottom: 2 }}>
                <Tabs
                  value={mainTabValue}
                  onChange={handleChangeMainTabs}
                  aria-label="basic tabs example"
                  TabIndicatorProps={{
                    sx: {
                      backgroundColor: '#2C6D47',
                    },
                  }}
                  sx={{
                    '.MuiButtonBase-root': {
                      fontWeight: '600',
                    },
                    '.Mui-selected': {
                      color: '#2C6D47!important',
                    },
                  }}
                >
                  <Tab value={0} label="Product Details" />
                  <Tab value={1} label="Pricing Manager" />
                </Tabs>
              </Box>
              {mainTabValue === 0 && <ProductDetailTabNew />}
              {mainTabValue === 1 && <PricingManagerTab />}
            </Box>

          </div>
        ):(
          <CircularProgress />
        )
      }
    </ProductContext.Provider>
  )
}

export default ProductDetails;
