import { yupResolver } from '@hookform/resolvers/yup';
import {
  Box,
  Button,
  ButtonBase,
  Chip,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  FormControl,
  FormControlLabel,
  IconButton,
  InputLabel,
  MenuItem,
  Select,
  Skeleton,
  Slide,
  Switch,
  ThemeProvider,
  Typography,
  createTheme,
} from '@mui/material';
import { useQuery, useQueryClient } from '@tanstack/react-query';
import React, { useEffect, useRef, useState } from 'react';
import { useForm } from 'react-hook-form';
import * as yup from 'yup';
import { useDropzone } from 'react-dropzone';
import { CKEditor } from '@ckeditor/ckeditor5-react';
import { Add } from '@mui/icons-material';
import Editor from 'ckeditor5-custom-build/build/ckeditor';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate, useParams } from 'react-router';
import { useJsApiLoader } from '@react-google-maps/api';

import BaseLocation from '../../../components/Modal/Base/BaseLocation';
import Comment from '../../../components/ui/Task/DrawerTask/Comment';
// import AssetsViewer from '../../../components/ui/AssetsViewer/AssetsViewer';
import AttachmentFiles from '../../../components/ui/Task/DrawerTask/AttachmentFiles';
import DynamicAttribute from '../../../components/Form/CRM/DynamicAttribute';
import StaticAttribute from '../../../components/Form/CRM/StaticAttribute';
import LocationPair from '../../../components/Form/CRM/LocationPair';
import ModalWrapper from '../../../components/ui/ModalWrapper';
import AddContact from '../../../components/Form/CRM/AddContact';

import crmApi from '../../../api/CRM/CRMCustomers';
import locationApi from '../../../api/content/location';
import accountingApi from '../../../api/accounting/accounting';
import { handleModal } from '../../../store/slices/modalSlice';

const labelStyle = {
  top: '-8px',
  '&.Mui-focused': { top: -8, background: '#fff' },
  '&.MuiFormLabel-filled, &.MuiInputLabel-shrink': { top: 0, background: '#fff' },
};
const groupArrayObject = (array) => {
  // Gunakan objek baru untuk menghindari perubahan sisi efek pada objek input
  return array.reduce((group, arr) => {
    const { category } = arr;

    // Gunakan objek kloning untuk menghindari perubahan langsung pada objek grup
    const gr = { ...group };

    // Periksa keberadaan properti 'name' di dalam category
    const categoryName = category && category.name;

    // Gunakan nullish coalescing operator untuk menetapkan nilai default jika categoryName null atau undefined
    if (categoryName in gr) {
      // Inisialisasi gr[categoryName] jika belum ada
      gr[categoryName] = gr[categoryName] || [];
      // Tambahkan arr ke dalam gr[categoryName]
      gr[categoryName].push(arr);
    } else {
      // Jika categoryName belum ada, buat entri baru di gr
      gr[categoryName] = [arr];
    }

    return gr;
  }, {});
};

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="down" ref={ref} {...props} />;
});

const mapLib = ['drawing', 'places'];

const {
  getCRMProfileAttribute,
  getDetailCRMProject,
  getFileTypeCRMProject,
  createCRMProject,
  postCRMProjectComments,
  postCRMProjectAttachments,
  getAllContacts,
  deleteCRMProjectAttachment,
  getAllMembers,
} = crmApi();
const { getDetailLocation } = locationApi();
const { getListBusinessUnit } = accountingApi();

const staticSchema = yup.object().shape({ name: yup.string().required('Name is a required field') });

export default function Detail() {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { projectId } = useParams();
  const isUsername = useSelector((state) => state.auth.user?.name);
  const activeClient = useSelector((state) => state.client.activeClient);
  const activeDetailClient = useSelector((state) => state.client.activeDetailClient);

  const [gridLayout, setGridLayout] = useState(null);
  const [projectProfile, setProjectProfile] = useState(null);
  const [projectData, setProjectData] = useState(null);
  const [projectBusinessUnit, setProjectBusinessUnit] = useState(null);
  const [projectPartner, setProjectPartner] = useState([]);

  const [schema, setSchema] = useState(null);
  const [location, setLocation] = useState(null);
  const [openLocation, setOpenLocation] = useState(null);
  const [filesAttachment, setFilesAttachment] = useState([]);
  const [commentData, setCommentData] = useState('');
  // eslint-disable-next-line no-unused-vars
  const [isPopoverAttachments, setPopoverAttachments] = useState(false);
  const [projectFileType, setProjectFileType] = React.useState(false);
  const [projectContact, setProjectContact] = useState([]);
  const [formulaAttributes, setFormulaAttributes] = useState([]);

  const [selectedMainLocation, SetSelectedMainLocation] = useState('');
  const [selectedLocations, SetSelectedLocations] = useState([]);
  const [reloadLocations, setReloadLocations] = useState(false);
  const [filterContact, setFilterContact] = useState(true);

  const queryClient = useQueryClient();

  const {
    register,
    trigger,
    getValues,
    setValue,
    watch,
    formState: { errors },
    control,
  } = useForm({
    resolver: yupResolver(staticSchema),
    defaultValues: {},
  });

  const projectIDRef = useRef(projectId);

  const onCloseHandler = () => {
    navigate('/crm/projects');
  };

  const { isLoaded } = useJsApiLoader({
    id: 'google-map-script',
    googleMapsApiKey: import.meta.env.VITE_MAP_KEY,
    libraries: mapLib,
  });

  const {
    data: dataAttribute,
    isFetching: isFetchingAttribute,
    refetch: refetchAttribute,
  } = useQuery({
    queryKey: ['crm-profile-attributes', projectProfile],
    enabled: !!projectProfile,
    queryFn: () => getCRMProfileAttribute({ profile: projectProfile, max_size: true }),
    onSuccess(res) {
      const properties = {};
      const requiredProperties = [];
      const keySchema = [];
      const errorProperties = {};
      res?.forEach((p) => {
        const attr = { ...p.attribute };
        const type = attr.value_type.toLowerCase();

        let usedType = type;
        if (['formula', 'numeric'].includes(type)) {
          usedType = 'number';
        } else if (['option', 'datetime', 'text'].includes(type)) {
          usedType = 'string';
        }
        properties[attr.key_name] = {
          type: usedType,
          required: attr.is_required,
          description: attr.name,
          origin: type,
          format: attr.format,
          configuration: p.configuration || null,
        };
        if (attr.is_required) {
          requiredProperties.push(attr.key_name);
          errorProperties[attr.key_name] = { required: 'Required field' };
        }
        if (type === 'option') {
          let options = attr.options.join('|');
          if (!attr.is_required) {
            options += '|null';
            options += "|''";
          }
          properties[attr.key_name].matches = `(${options})`;
        }
        if (type === 'numeric' && !attr.is_required) {
          properties[attr.key_name].default = null;
          properties[attr.key_name].nullable = true;
        }
        if (type === 'formula') {
          properties[attr.key_name].default = null;
          properties[attr.key_name].nullable = true;
        }
        keySchema.push(attr.key_name);
      });

      const newSchema = {
        $schema: 'http://json-schema.org/draft-07/schema#',
        $id: 'http://example.com/person.schema.json',
        title: 'Project',
        description: 'CRM Project',
        type: 'object',
        properties,
        required: requiredProperties,
      };

      setSchema({ schema: newSchema, errorSchema: errorProperties, keys: keySchema });
    },
    refetchOnWindowFocus: false,
  });

  const { data: project, refetch: refetchProjectDetail } = useQuery({
    queryKey: ['crm-project-detail', projectId],
    enabled: !!projectId,
    queryFn: () => getDetailCRMProject(projectId),
    onSuccess(res) {
      if (res.groups) {
        const groups = res.groups;
        const arrFormulaAttribute = [];
        const newGridLayout = groups.map((gr, i) => {
          const { dimension: d, attributes: attr } = gr;
          const objGroup = {
            w: d[2],
            h: d[3],
            x: d[0],
            y: d[1],
            i: `group|${i}|${gr.id}|${gr.name}`,
            moved: false,
            static: true,
          };
          if (attr?.length) {
            objGroup.activeLayout = attr.map((a, idx) => {
              if (a.value_type === 'Formula') {
                arrFormulaAttribute.push({ keyName: a.key_name, value: a.value });
              }
              const { dimension: dim } = a;
              return {
                w: dim[2],
                h: dim[3],
                x: dim[0],
                y: dim[1],
                i: `${idx}|group|${i}|${gr.id}|${gr.name}|${a.key_name}|${a.value}`,
                moved: false,
                static: true,
              };
            });
          }
          return objGroup;
        });
        setFormulaAttributes(arrFormulaAttribute);
        setGridLayout(newGridLayout);
      }
      if (res) {
        setValue('name', res.name, { shouldTouch: true, shouldDirty: true, shouldValidate: true });
        setValue('location', res.location?.id, { shouldTouch: true });
        if (res.location) {
          setLocation({ ...res.location, label: res.location?.name });
          SetSelectedMainLocation(res.location.id);
          SetSelectedLocations([res.location.id]);
        }
        setProjectProfile(res.profile?.id);
        setProjectBusinessUnit(res.business_unit?.id || null);
        setProjectData(res);
        setProjectPartner(res.partners || []);
      }
      if (res.contacts?.length) {
        setProjectContact(res.contacts);
      } else {
        setProjectContact([]);
      }

      if (res.files?.length) {
        const files = groupArrayObject(res.files);
        setFilesAttachment(files);
      } else if (typeof filesAttachment === 'object' && Object.keys(filesAttachment).length) {
        setFilesAttachment([]);
      }
    },
    refetchOnWindowFocus: false,
  });
  useQuery({
    queryKey: ['crm-project-location', location],
    enabled: !!projectId && !!location && !!['string', 'number'].includes(typeof location),
    queryFn: () => getDetailLocation(location),
    onSuccess(res) {
      if (res.data) {
        setValue('location', res.data.name || res.data.address, { shouldDirty: true });
        setLocation({ ...res.data, label: res.data.name });
      }
    },
    refetchOnWindowFocus: false,
  });
  const { data: dataBusinessUnit } = useQuery({
    queryKey: ['crm-project-business-unit', activeClient],
    queryFn: () => getListBusinessUnit({ client: activeClient, max_size: true, ordering: 'name' }),
    refetchOnWindowFocus: false,
  });
  const { data: dataMembers, refetch: refetchPartner } = useQuery({
    queryKey: ['crm-project-members', activeClient],
    queryFn: () => getAllMembers({ client: activeClient, max_size: true, ordering: 'name' }),
    refetchOnWindowFocus: false,
  });
  const { data: dataContact, refetch: refetchContact } = useQuery({
    queryKey: ['crm-projects-contact-partners', projectPartner, filterContact],
    enabled: !!activeClient && !!project,
    queryFn: () => {
      const contactParam = { client: activeClient, max_size: true, ordering: 'name' };
      if (filterContact && projectPartner?.length) {
        contactParam.member_or_internal = projectPartner.map((p) => p.id);
      }
      return getAllContacts(contactParam);
    },
    onSuccess: () => {},
    onError: (error) => {
      console.log(error);
    },
    refetchOnWindowFocus: false,
  });
  const { data: fileTypes } = useQuery({
    queryKey: ['crm-project-file-type', projectId],
    enabled: !!projectId && !!projectProfile,
    queryFn: () => getFileTypeCRMProject({ profile: projectProfile }),
    refetchOnWindowFocus: false,
  });

  const onMainFormSubmit = async () => {
    const isValid = await trigger(['name']);
    return isValid;
  };

  const onCreateProject = (_, param) => {
    createCRMProject(
      {
        name: getValues('name'),
        contacts: projectContact?.length ? projectContact.map((c) => c.id) : [],
        location: location?.id || '',
        business_unit: projectBusinessUnit || null,
        partners: projectPartner?.length ? projectPartner.map((c) => c.id) : [],
        profile: projectProfile,
        // attributes: formData,
      },
      'patch',
      projectId
    )
      .then(() => {
        if (param && param === 'no-redirect') {
          refetchProjectDetail();
        } else {
          onCloseHandler();
        }
        queryClient.invalidateQueries({ queryKey: ['crm-projects', activeClient] });
        queryClient.invalidateQueries({ queryKey: ['crm-project-attributes', activeClient] });
      })
      .catch((err) => console.log(err));
  };

  const onPatchProjectAttribute = (formData) => {
    createCRMProject(
      {
        attributes: formData,
      },
      'patch',
      projectId
    )
      .then(() => {
        refetchProjectDetail();
        queryClient.invalidateQueries({ queryKey: ['crm-projects', activeClient] });
        queryClient.invalidateQueries({ queryKey: ['crm-project-attributes', activeClient] });
      })
      .catch((err) => console.log(err));
  };

  const pairLocation = (dataLocation) => {
    setValue('location', dataLocation.name || dataLocation.address, { shouldDirty: true });
    // console.log(dataLocation);
    setLocation({ ...dataLocation, label: dataLocation.name });
    setOpenLocation(false);
  };

  const onPostComment = () => {
    postCRMProjectComments({ project: projectId, comment: commentData })
      .then(() => {
        setCommentData('');
        refetchProjectDetail();
      })
      .catch((err) => {
        console.log('err', err);
      });
  };

  const deleteAttachments = (attachmentId) => {
    if (attachmentId) {
      deleteCRMProjectAttachment(attachmentId)
        .then(() => refetchProjectDetail())
        .catch((err) => console.log(err));
    }
  };

  useEffect(() => {
    if (projectIDRef.current && projectIDRef.current !== projectId) {
      projectIDRef.current = projectId;
      refetchProjectDetail();
      refetchAttribute();
    }
  }, [projectId, refetchProjectDetail, refetchAttribute]);

  const handleChangeContact = (event) => {
    const {
      // Config
      target: { value },
    } = event;

    const duplicateIds = value
      .map((e) => e.id)
      .map((e, i, final) => final.indexOf(e) !== i && i)
      .filter((obj) => value[obj])
      .map((e) => value[e].id);

    setProjectContact(typeof value === 'string' ? value.split(',') : value.filter((v) => !duplicateIds.includes(v.id)));
  };
  const handleChangePartners = (event) => {
    const {
      // Config
      target: { value },
    } = event;
    const duplicateIds = value
      .map((e) => e.id)
      .map((e, i, final) => final.indexOf(e) !== i && i)
      .filter((obj) => value[obj])
      .map((e) => value[e].id);
    setProjectPartner(typeof value === 'string' ? value.split(',') : value.filter((v) => !duplicateIds.includes(v.id)));
  };
  const setOpenLocationSelector = (param) => {
    if (!!param) {
      dispatch(handleModal({ modalId: activeClient, componentName: 'locationPair', modalData: null }));
    } else {
      dispatch(handleModal({ modalId: '', componentName: '', modalData: '' }));
    }
  };
  const handleChangeContactFilter = (event) => {
    setFilterContact(event.target.checked);
    setProjectContact([])
  };

  return (
    isLoaded && (
      <>
        <Box className="w-full h-full px-6 py-6 space-y-3">
          <Typography id="create-crm-project" className="mb-4" variant="h6" component="h2">
            Project
          </Typography>
          <section className="w-full h-full">
            <div className="grid grid-cols-6 gap-8">
              <div className="col-span-4">
                <StaticAttribute
                  form={{ control, errors, register, watch }}
                  state={{
                    dataContact: dataContact?.data?.results,
                    projectContact,
                    projectProfile,
                    location,
                    openLocation,
                    isEdit: project,
                    dataBusinessUnit,
                    projectBusinessUnit,
                    projectPartner,
                    dataMembers,
                  }}
                  action={{
                    handleChangeContact,
                    refetchContact,
                    setOpenLocation,
                    setOpenLocationSelector,
                    setProjectProfile,
                    pairLocation,
                    setProjectBusinessUnit,
                    handleChangePartners,
                    refetchPartner,
                  }}
                />

                {projectProfile && isFetchingAttribute && <Skeleton />}
                {projectProfile && !isFetchingAttribute && schema && (
                  <DynamicAttribute
                    attributes={dataAttribute}
                    schema={schema}
                    onCloseHandler={onCloseHandler}
                    onCreateProject={onCreateProject}
                    onPatchProjectAttribute={onPatchProjectAttribute}
                    triggerMain={trigger}
                    onMainFormSubmit={onMainFormSubmit}
                    isEdit={project}
                    gridLayout={gridLayout}
                  >
                    {/* Could put notification here */}
                  </DynamicAttribute>
                )}
              </div>

              <div className="col-span-2 border-l pl-8 max-h-[90vh] overflow-y-auto">
                <div className="flex flex-col gap-4 pt-2">
                  <div className="w-full flex flex-col">
                    <div className="w-full flex gap-4">
                      <FormControl fullWidth>
                        <InputLabel htmlFor="project-profile" sx={labelStyle} shrink={!!projectContact?.length}>
                          Contact
                        </InputLabel>
                        <Select
                          id="project-contacts"
                          multiple
                          value={projectContact}
                          className="min-h-[2.5rem] h-auto"
                          sx={{ width: '100%', px: 0.2, py: 0.2 }}
                          onChange={handleChangeContact}
                          displayEmpty
                          MenuProps={{ PaperProps: { sx: { maxHeight: 'calc(100% - 25vh)' } } }}
                          renderValue={(selected) => (
                            <Box sx={{ display: 'flex', flexWrap: 'wrap', gap: 0.5 }}>
                              {selected.map((value) => {
                                return <Chip key={value.id} label={value.name} className="h-6" />;
                              })}
                            </Box>
                          )}
                        >
                          {dataContact?.data?.results.length > 0 &&
                            dataContact.data.results.map((p) => (
                              <MenuItem key={p.id} value={p}>
                                {p.name}
                              </MenuItem>
                            ))}
                        </Select>
                      </FormControl>
                      <IconButton
                        aria-label="add contact"
                        color="primary"
                        className="border border-solid border-gray-300 rounded-md bg-white"
                        onClick={() => dispatch(handleModal({ modalId: '', componentName: 'AddNewContact' }))}
                      >
                        <Add />
                      </IconButton>
                    </div>
                    <FormControlLabel control={<Switch checked={filterContact} onChange={handleChangeContactFilter} inputProps={{ 'aria-label': 'controlled' }} />} label={<Typography className='text-[#00000099] text-sm'>{filterContact ? `contact from ${activeDetailClient.name}` : `contact from ${activeDetailClient.name} partner`}</Typography>} />
                  </div>
                  <div className="">
                    <InputLabel htmlFor="project-comment" className="mb-2">
                      Attachments
                    </InputLabel>
                    {filesAttachment &&
                      Object.keys(filesAttachment).map((file, index) => {
                        const contentAttachments = filesAttachment[file]?.map((att) => (
                          <AttachmentFiles key={att?.file} file={att} index={index} setPopoverAttachments={setPopoverAttachments} isCRM deleteAttachments={deleteAttachments} />
                        ));
                        return (
                          <div key={file}>
                            <span className="mb-2">{file}</span>
                            <div className="flex flex-wrap gap-4">{contentAttachments}</div>
                          </div>
                        );
                      })}
                    <div className="flex space-x-2 flex-wrap items-center">
                      <ButtonBase className="p-2 flex items-center justify-center border border-dashed border-gray-300 rounded-md cursor-pointer">
                        <Add className="w-[32px] h-[32px]" onClick={() => setProjectFileType(true)} />
                      </ButtonBase>
                    </div>
                  </div>
                  <div className="relative overflow-y-auto">
                    <InputLabel htmlFor="project-comment" className="mb-2">
                      Comment
                    </InputLabel>
                    {project &&
                      project.comments &&
                      project.comments.length > 0 &&
                      project.comments.map((c, index) => {
                        return (
                          <Comment
                            key={c.id}
                            comment={c}
                            isUsername={isUsername}
                            getTopicDetails={refetchProjectDetail}
                            index={index}
                            setPopoverAttachments={setPopoverAttachments}
                            isTopic={false}
                          />
                        );
                      })}
                    <CKEditor
                      id="project-comment"
                      placeholder="Comment"
                      className="ml-2 w-full border-0"
                      editor={Editor}
                      config={{ toolbar: { items: [] }, placeholder: '', minHeight: 172 }}
                      data={commentData}
                      onChange={(_, editor) => {
                        setCommentData(editor.getData());
                      }}
                      onReady={(editor) => {
                        editor.editing.view.change((writer) => {
                          writer.setStyle('min-height', '180px !important', editor.editing.view.document.getRoot());
                          writer.setStyle('margin-top', '-2px', editor.editing.view.document.getRoot());
                        });
                      }}
                    />
                    <Button className="ml-auto mt-4" type="button" variant="contained" onClick={onPostComment}>
                      Comment
                    </Button>
                  </div>
                </div>
              </div>
            </div>
          </section>
        </Box>

        <DialogSelectionFileType isEdit={project} fileTypes={fileTypes} open={projectFileType} onClose={() => setProjectFileType(false)} refetch={refetchProjectDetail} />

        {/* <BaseLocation
          open={!!openLocation && openLocation !== 'edit'}
          close={() => {
            if (!project && location) {
              setLocation(null);
            }
            setOpenLocation(false);
          }}
          action={pairLocation}
        /> */}

        <ModalWrapper componentName="locationPair" header="Pair Location" maxWidth="xl">
          <LocationPair
            mode={'crmProject'}
            submit={pairLocation}
            reload={setReloadLocations}
            StateLocations={selectedLocations}
            StateMainLocation={selectedMainLocation}
            SetStateLocations={SetSelectedLocations}
            SetStateMainLocation={SetSelectedMainLocation}
          />
        </ModalWrapper>
        <ModalWrapper componentName="AddNewContact" header="Add New Contact" maxWidth="sm">
          <AddContact activeClient={activeClient} reload={refetchContact} type="CRM Project" />
        </ModalWrapper>
      </>
    )
  );
}

const menuTheme = createTheme({
  components: {
    MuiPopover: { styleOverrides: { root: ({ theme }) => theme.unstable_sx({}) } },
    MuiMenu: { styleOverrides: { root: ({ theme }) => theme.unstable_sx({ zIndex: 100001 }) } },
  },
});

function DialogSelectionFileType({ open, onClose, isEdit, refetch, fileTypes }) {
  const [fileType, setFileType] = useState(null);

  const {
    getRootProps,
    getInputProps,
    open: openDialog,
  } = useDropzone({
    noClick: true,
    multiple: false,
    onDrop: (acceptedFiles) => {
      if (fileType) {
        postCRMProjectAttachments({
          project: isEdit.id,
          category: fileType,
          file: acceptedFiles[0],
        })
          .then(() => {
            refetch();
            onClose();
          })
          .catch((err) => {
            console.log('err', err);
          });
      }
    },
  });

  useEffect(() => {
    if (!open) {
      setFileType(null);
    }
  }, [open]);

  return (
    <ThemeProvider theme={menuTheme}>
      <Dialog
        sx={{ zIndex: 10000, '& > .MuiDialog-container > .MuiPaper-root': { width: 480 } }}
        open={open}
        TransitionComponent={Transition}
        keepMounted
        onClose={onClose}
        aria-describedby="crm-attachments-dialog"
      >
        <DialogTitle>Category</DialogTitle>
        <DialogContent>
          <DialogContentText id="crm-attachments-dialog">
            <FormControl fullWidth sx={{ paddingTop: 1 }}>
              <InputLabel
                htmlFor="project-file-type"
                sx={{
                  top: '0px',
                  '&.Mui-focused': { top: -16, background: '#fff' },
                  '&.MuiInputLabel-shrink': { top: 8, background: '#fff' },
                }}
                shrink={!!fileType}
              >
                Profile
              </InputLabel>
              <Select
                id="project-file-type"
                value={fileType}
                required
                className="h-10"
                sx={{ width: '100%', px: 0.2, py: 0.2 }}
                onChange={(e) => setFileType(e.target.value)}
                displayEmpty
              >
                {fileTypes?.length > 0 &&
                  fileTypes?.map((p) => (
                    <MenuItem key={p.id} value={p.id}>
                      {p.name}
                    </MenuItem>
                  ))}
              </Select>
            </FormControl>
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button variant="outlined" onClick={onClose}>
            Cancel
          </Button>
          <div className="ml-2" {...getRootProps()}>
            <Button variant="contained" onClick={openDialog}>
              <input {...getInputProps()} />
              Upload
            </Button>
          </div>
        </DialogActions>
      </Dialog>
    </ThemeProvider>
  );
}
